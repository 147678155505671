<template>
    <div>
      <event-mobile v-if="isMobile" />
      <event-dekstop v-else />
    </div>
  </template>
  
  <script>
  import EventDekstop from "@/components/event/desktop/Event.vue";
  import EventMobile from "@/components/event/mobile/Event.vue";
  
  export default {
    name: "EventPage",
    components: {
      EventDekstop,
      EventMobile,
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = window.innerWidth <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
    }
  };
  </script>
  