<template>
    <div>
      <DesktopPrakerja v-if="!isMobile" />
      <MobilePrakerja v-if="isMobile" />
    </div>
  </template>
  
  <script>
  import DesktopPrakerja from '@/components/prakerja/desktop/Prakerja'
  import MobilePrakerja from '@/components/prakerja/mobile/Prakerja'
  export default {
    components: {
      MobilePrakerja,
      DesktopPrakerja,
    },
    metaInfo() {
      const thisPath = window.location.href
      return {
        title: this.$route.meta.title,
        htmlAttrs: {
          lang: "id",
          amp: true,
        },
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name:"keywords", content: "Prakerja,HiColleagues,Prakerja HiColleagues"},
          { name: "description", content: this.$route.meta.description},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": this.$route.meta.title,
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
    data() {
      return{
        isMobile: false
      }
    },
    methods: {
        handleView() {
            this.isMobile = window.innerWidth <= 990
        }
    },
    mounted() {
      this.$gtag.pageview(this.$route)
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView()
    }
  }
  </script>