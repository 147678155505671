<template>
    <div>
        <div class="mt-5 master-list" v-if="!isMobile">
            <div v-for="n in benefits" :key="n.id" class="content-card-parent">
                <div class="content-card">
                    <div class="left-span"><img :src="n.image" width="100" height="100"/></div>
                    <div class="title-2 font-poppins">{{n.title}}</div>
                    <div class="desc d-flex"><span class="font-poppins">{{n.desc}}</span></div>
                </div>
            </div>
        </div>

        <div style="display: flex; flex-direction: column !important; gap: 24px;" v-else>
            <div v-for="n in benefits" :key="n.id" class="card-mob mt-4">
                <div class="left-span"><img :src="n.image" width="48" height="48"/></div>
                <div style="text-align: left;">
                    <div class="title-2" style="text-align: left; height: min-content; color: #36373F; font-size: 16px;">{{n.title}}</div>
                    <div class="desc d-flex" style="text-align: left; color: #575966; font-size: 14px; font-weight: 400"><span class="font-poppins">{{n.desc}}</span></div>
                </div>
            </div>
        </div>

        <!-- <span>Path Career</span>
        <h5 class="title">Potensial Karir ke Depan setelah Menguasai Skill ini</h5>
        <div class="scrolling-wrapper">
            <div class="card d-flex" style="margin-top:20px;font-size:14px;">
                <div v-for="n in careers" :key="n.id" class="btn-stroke-grey">{{n.name}}</div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data () {
        return {
            careers: [
                {name: "Fullstack Developer", id: 1},
                {name: "Frontend Developer", id: 2},
                {name: "Backend Developer", id: 3},
                {name: "Software Enginer", id: 4},
            ],
            benefits: [
                {title: 'Instruktur Berpengalaman', desc: 'Pelatihan akan dibimbing ileh instruktur yang berpengalaman di bidangnya', image: '/img/prakerja/ic_competent_trainer.png', id: 1},
                {title: 'E-Certificate', desc: 'Bukti peserta telah mengikuti & berhasil menyelesaikan pelatihan', image: '/img/prakerja/ic_sertifikat_kompetensi.png', id: 2},
                {title: 'Perluas Koneksi', desc: 'Berjejaring dengan pihak yang relevan dengan minatmu', image: '/img/prakerja/ic_connection.png', id: 3},
                {title: 'Bangun Portfolio', desc: 'Persiapkan bekal kerja / wirausaha dengan hasil uji keterampilan yang kredibel', image: '/img/prakerja/ic_portfolio.png', id: 4},
            ],
            isMobile: false
        }
    },
    methods: {
        handleView() {
            console.log(window.innerWidth);
            this.isMobile = window.innerWidth <= 990
        }
    },
    mounted() {
        this.handleView()
    }
}
</script>

<style src="./css/benefitPrakerja.css" scoped></style>