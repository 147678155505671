<template>
  <div class="main-container">
    <img :src="event.img_urls.mobile.product_detail" class="img-ctn-mobile" />
    <div class="banner-content">
      <h2 class="event-name">{{ event.name }}</h2>
      <div class="event-ctn-label">
        <span>{{ event.category.name }}</span>
      </div>
      <p class="description" v-html="event.description"></p>
      <h2 class="title-event-mekanisme">Mekanisme Kelas</h2>
      <div class="event-mekanisme">
        <div class="stroke-gray-box">
          <div class="icon-box">
            <DateIcon fill="#575966" />
          </div>
          <div class="event-mekanisme-detail">
            <div class="title-mechanism"><span>Hari & Tanggal</span></div>
            <div class="sub-title-mechanism">
              <span>{{ moment(event.session_schedules[0].date_start) }}</span>
            </div>
          </div>
        </div>
        <div class="stroke-gray-box">
          <div class="icon-box">
            <Clock fill="#575966" />
          </div>
          <div class="event-mekanisme-detail">
            <div class="title-mechanism"><span>Jam</span></div>
            <div class="sub-title-mechanism">
              <span>{{ event.session_schedules[0].start_time }} - {{ event.session_schedules[0].end_time }} WIB</span>
            </div>
          </div>
        </div>
        <div class="stroke-gray-box">
          <div class="icon-box">
            <Methode fill="#575966" />
          </div>
          <div class="event-mekanisme-detail">
            <div class="title-mechanism"><span>Metode</span></div>
            <div class="sub-title-mechanism">
              <span>{{ event.method | toMethode }}</span>
            </div>
          </div>
        </div>
        <div class="stroke-gray-box" v-if="event.method === 'Offline'">
          <div class="icon-box">
            <MapIc />
          </div>
          <div class="event-mekanisme-detail">
            <div class="title-mechanism"><span>Lokasi Pelaksanaan</span></div>
            <div class="sub-title-mechanism">
              <h4>{{ event.location_name }}</h4>
              <p>{{ event.location_address }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="event.syllabuses.length > 0" class="materi-silabues">
        <h2 class="title-materi-silabus">Materi Silabus</h2>
        <div class="materi-silabus" v-for="(item, idx) in event.syllabuses" :key="idx">
          <span class="sub-title">Sesi {{ idx + 1 }}</span>
          <ul style="margin-top: 8px;">
            <li class="silabus-item" v-for="(item2, idx2) in item" :key="idx2">{{ item2 }}</li>
          </ul>
        </div>
      </div>
      <h2 class="title-narasumber">Narasumber</h2>
      <div
        class="narasumber"
        v-for="item in event.instructors"
        :key="(item.id)"
      >
        <img :src="$utils.loadImage(item.image_url)" alt="Profile" class="narasumber-img" />
        <h2 class="narasumber-name">{{ item.name }}</h2>
        <span class="narasumber-work">{{ item.position }}</span>
        <span class="narasumber-work">{{ item.company ? "at " + item.company : "" }}</span>
        <p class="description-2">{{ item.description }}</p>
      </div>
      <h2 class="benefit">Benefit</h2>
      <div
        v-for="(item, idx) in event.benefits"
        :key="idx"
        class="benefit-list"
      >
        <div>
          <img src="/img/icons/bullet.png" style="width: 24px; height: 24px;" alt="Bullet" />
        </div>
        <span class="benefit-text">{{ item }}</span>
      </div>
      <div class="box-checkout">
        <div class="event-due-date" v-if="event.expired.length">
          <div class="event-due-date-ctn">
            <span>{{ event.expired[0] }}</span>
            <span>Hari</span>
          </div>
          <span>:</span>
          <div class="event-due-date-ctn">
            <span>{{ event.expired[1] }}</span>
            <span>Jam</span>
          </div>
          <span>:</span>
          <div class="event-due-date-ctn">
            <span>{{ event.expired[2] }}</span>
            <span>Menit</span>
          </div>
          <span>:</span>
          <div class="event-due-date-ctn">
            <span>{{ event.expired[3] }}</span>
            <span>Detik</span>
          </div>
        </div>
        <div class="event-due-date" v-else>
          <div class="event-due-date-ctn">EXPIRED</div>
        </div>
        <div class="button-checkout" v-if="event.expired.length && !isFullRegistrant">
          <button-yellow
            name="DAFTAR SEKARANG"
            width="400px"
            @click="() => toCheckout()"
          />
          <button-outline
            name="KONSULTASI"
            width="170px"
            @click="() => toKonsultasi()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ButtonOutline from "../../others/ButtonOutline.vue";
import ButtonYellow from "../../others/ButtonYellow.vue";
import MapIc from '@/assets/icons/Map.vue';
import Clock from "@/assets/icons/Clock.vue";
import DateIcon from "@/assets/icons/Date.vue";
import Methode from "@/assets/icons/Methode.vue";

export default {
  name: "EventDetailMobile",
  components: {
    ButtonOutline,
    ButtonYellow,
    MapIc,
    Clock,
    DateIcon,
    Methode
  },
  data() {
    return {
      event: {
        name: "",
        learning_url: "",
        caption: "",
        img_urls: {
          desktop: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
          mobile: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
        },
        description: "",
        method: "",
        total_session: 0,
        day_session: "",
        labels: [],
        benefits: [],
        instructors: [
          {
            name: "",
          },
        ],
        registration_close_date: "",
        price: 0,
        syllabuses: [],
        session_schedules: [],
        is_active: false,
        expired: [],
      },
      isAlertErr: false,
    };
  },
  methods: {
    async getEventDetail() {
      try {
        const resp = await this.$http.get("/v1/event/" + this.$route.params.id);
        const item = resp.data.data.event;
        this.event = {
          ...item,
          session_schedules: item.session_schedules.length
            ? item.session_schedules
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        };
        this.getTotalRegistran()
      } catch {
        this.$router.push('/event')
      }
    },
    async getTotalRegistran() {
      try {
        const resp = await this.$http.get(`/v1/event/${this.event.id}/registrant`);
        this.total_registrants = resp.data.data.total_registrants  
        if (this.total_registrants >= this.event.participant_quota && this.event.participant_quota !== 0) {
          this.isFullRegistrant = true
        }
      } catch (error) {
        console.log(error);
      }
    },
    toCheckout() {
      if (!this.event.expired.length) {
        this.isAlertErr = true;
        return;
      }
      this.$router.push("/checkout/event/" + this.event.id);
    },
    toKonsultasi() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date()) {
        return true
      } else {
        return false
      }
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(
          this.event.registration_close_date
        );
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.event.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.event.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
  },
  mounted() {
    this.getEventDetail();
    this.countDownTimer();
    window.scrollTo(0, 0);
  },
};
</script>
<style src="./eventDetail.css" scoped></style>
