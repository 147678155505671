<template>
    <div>
      <DesktopCareerDetail v-if="!isMobile"/>
      <MobileCareerDetail v-if="isMobile"/>
    </div>
  </template>
  <script>
  import DesktopCareerDetail from "@/components/career/detail/desktop/Detail.vue";
  import MobileCareerDetail from  "@/components/career/detail/mobile/Detail.vue"
  
  export default {
    components: {
        DesktopCareerDetail,
        MobileCareerDetail,
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = window.innerWidth <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
    }
  };
  </script>
  