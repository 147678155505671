<template>
  <v-app>
    <v-main>
      <HitBanner :end-date="endDate" :dialog="dialog" :img-src="popupImgSrc" :persistent="persistent" :isSales="isSales" :link-url="linkUrl"
        @close="closePopup" />
      <router-view></router-view>
    </v-main>
    <!-- <NavigationBottomMenu v-if="mobileView && isNavMobileShow"/> -->
  </v-app>
</template>


<script>
import HitBanner from './components/HitBanner.vue';
export default {
  name: "App",
  components: { HitBanner, },
  data() {
    return {
      isMobile: false,
      endDate: "",
      dialog: false,
      popupImgSrc: "",
      linkUrl: "",
      persistent: true,
      isSales: true,
      hitBannnerSeen: false,
    };
  },
  methods: {
    async fetchHitBanner() {
      try {
        const response = await this.$http.get("/v1/banner?category=hit&is_active=true");
        const banners = response.data.data.banners;
        if (!banners) return
        this.popupImgSrc = banners[0].img_link_url_web;
          this.linkUrl = banners[0].link_url;
          const type = banners[0].type;
          if (type == "sales") {
            this.isSales = true;
          } else {
            this.isSales = false;
          }
          this.endDate = banners[0].end_date_time;
          this.dialog = true;
          sessionStorage.setItem("hitBannerSeen", "true");
      } catch (error) {
        console.error("Error fetching hit banners:", error);
      }
    },
    handleView() {
      this.isMobile = window.innerWidth <= 990;
      this.persistent = this.isMobile ? false : true;
    },
    closePopup() {
      this.dialog = false;
    },
    checkHitBannerSeen() {
      if (sessionStorage.getItem("hitBannerSeen")) {
        this.dialog = false;
      } else {
        this.fetchHitBanner();
      }
    },
  },
  beforeMount() {
    this.handleView();
    this.$gtag.pageview(this.$route);
    this.checkHitBannerSeen();
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

h1,
h2,
h3,
h4,
h5,
p,
div,
a{
  font-family: 'Poppins';
}

h1,
h2,
h3,
h4,
h5 {
  color: #36373F;
}

.line-height-normal {
  line-height: 33px
}

.letter-spacing {
  letter-spacing: 2px;
}
.see-other-faq-corp {
  margin-top: 20px;
          border: 1px solid #162ca2;
          border-radius: 10px;
          padding: 23px;
          letter-spacing: 0px;
          text-transform: capitalize;
          color: #162ca2 !important;
}

.btn-mobile-slider-corp {
  display: flex;
              justify-content: center;
              align-items: center;
              background-color: #162ca2 !important;
              border-radius: 100%;
              width: 33px !important;
              height: 33px !important;
              color: white !important;
}

.letter-spacing-art {
  letter-spacing: 0.5px;
}

.font-poppins {
  font-family: 'Poppins', sans-serif !important;
}

.container {
  min-width: 1400px;
}

.container-size {
  margin-top: 45px;
}

.justify-content-between {
  justify-content: space-between;
}

.text-justify {
  text-align: justify;
}

.gap-2 {
  gap: 20px;
}

.line-height-normal {
  line-height: 35px;
}

.bold {
  font-weight: bold;
}

.main-shadow {
  box-shadow: 2px 6px 10px 3px rgba(13, 28, 113, 0.1) !important;
}

.btn-stroke-grey {
  border: 1px solid #7B7E8C;
  border-radius: 30px;
  padding: 13px 32px;
  margin-right: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-stoke-blue {
  display: flex;
  border: 1px solid #162CA2;
  border-radius: 12px;
  margin: 40px 0px;
  padding: 10px 40px;
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  color: #162CA2;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.btn-blue {
  display: flex;
  background: #162CA2;
  border-radius: 12px;
  margin: 40px 0px;
  padding: 10px 40px;
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.btn-white {
  display: flex;
  background: white;
  border-radius: 12px;
  margin: 40px 0px;
  padding: 10px 40px;
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  color: #162CA2;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.btn-white-oval {
  background: #FFFFFF;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 15px;
  padding: 5px 16px;
  color: #162CA2;
  font-weight: 400;
  margin-right: 12px;
}

.btn-radius {
  margin-left: auto;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
}

.img-btn-right {
  margin-left: 10px;
  width: 12px;
  height: 12px;
  margin-top: 5px;
}

.sub-title-span {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: 700;
}

.icon-class {
  width: 12px;
  height: 11px;
  margin-right: 7px;
}


.class-list-name {
  font-weight: 700;
  font-size: 20px;
  padding-left: 28px;
  padding-right: 28px;
  text-align: center;
}

.level-div {
  font-size: 14px;
  font-weight: 400;
  padding: 4px 11px;
  background: linear-gradient(96.74deg, #F0ECFE 0%, #CDDDFE 100%);
  border-radius: 12px;
  margin-right: 10px;
}

.method-div {
  font-size: 14px;
  font-weight: 400;
  padding: 4px 11px;
  background: linear-gradient(91.93deg, #EDFAE1 0%, #CEF3EA 100%);
  border-radius: 12px;
}

.trainer-section {
  display: flex;
  padding-left: 28px;
  margin-top: 15px;
}

.trainer-info {
  margin-left: 15px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 17px;
}

.prakerja-card {
  background-size: cover;
  height: 440px;
  max-width: 336px;
  border-radius: 20px !important;
  box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
}

.bg-rounded {
  background: #ffffff;
  border-radius: 25px;
  color: #36373f;
  text-align: left;
  padding: 4px;
  margin-bottom: 10px;
}

.custom-desc-text-size {
  white-space: pre-wrap;
}

.class-type-section {
  background: linear-gradient(180deg, rgba(253, 250, 231, 0) 0%, #FDFAE7 100%);
  padding: 80px 0px;
  text-align: center;
}

.blue-rounded-btn {
  background: #162CA2;
  border-radius: 70px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 79px;
  margin-right: 20px;
  cursor: pointer;
}

.category-list-class {
  background-size: cover;
  height: 480px;
  width: 320px;
  border-radius: 20px !important;
  box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
}

.class-list-img {
  width: 270px;
  height: 230px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 15px;
}

.info-dev {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.desc-class {
  font-weight: 400;
  font-size: 15px;
  padding-left: 28px;
  padding-right: 28px;
  text-align: center;
}

.stroke-btn-grey {
  border: 1px solid #ACAFBF;
  background: #fff;
  border-radius: 70px;
  color: #36373F;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 79px;
  margin-right: 20px;
  cursor: pointer;
}

.bg-gradient-blue {
  background: linear-gradient(286.97deg, #179EFF -0.7%, #162CA2 100%);
  border-radius: 90px 90px 0px 0px;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  padding: 110px 0px;
  text-align: center;
}

.bg-gradient-yellow {
  background: linear-gradient(96.84deg, #FEE77C 2.38%, #FFC122 105.03%);
  box-shadow: 0px 2px 3px rgba(13, 28, 113, 0.1);
  border-radius: 15px;
  padding: 12px 77px;
  cursor: pointer;
}

.bg-gradient-white {
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(13, 28, 113, 0.1);
  border-radius: 15px;
  padding: 12px 77px;
  cursor: pointer;
}

.text-blue {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  color: #162CA2;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 15px 0px;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
  -webkit-overflow-scrolling: touch;
}

.card {
  display: inline-block;
}

@media screen and (max-width: 1440px) {
  .container {
    min-width: 94% !important;
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .bg-gradient-blue {
    background: linear-gradient(286.97deg, #179EFF -0.7%, #162CA2 100%);
    border-radius: 30px 30px 0px 0px;
    padding: 35px 0px;
    text-align: center;
  }

  .custom-text-size {
    font-size: 14px !important;
  }

  .custom-desc-text-size {
    font-size: 13px !important;
  }

  .sub-title-span {
    font-size: 20px;
    font-weight: 700;
  }

  .prakerja-card {
    background-size: cover;
    height: 440px;
    width: 310px;
    border-radius: 20px !important;
    box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
  }

  .level-div {
    font-size: 11px;
    font-weight: 400;
    padding: 4px 11px;
    background: linear-gradient(96.74deg, #F0ECFE 0%, #CDDDFE 100%);
    border-radius: 12px;
    margin-right: 10px;
  }

  .method-div {
    font-size: 11px;
    font-weight: 400;
    padding: 4px 11px;
    background: linear-gradient(91.93deg, #EDFAE1 0%, #CEF3EA 100%);
    border-radius: 12px;
  }

  .trainer-info {
    margin-left: 0px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 17px;
  }

  .class-type-section {
    background: linear-gradient(180deg, rgba(253, 250, 231, 0) 0%, #FDFAE7 100%);
    padding: 40px 12px;
    text-align: center;
  }

  .desc-class {
    font-weight: 400;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 25px;
    text-align: left;
  }

  .category-list-class {
    display: flex;
    background-size: cover;
    height: 200px;
    width: 100%;
    border-radius: 20px !important;
    box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
  }

  .class-list-img {
    width: 110px;
    height: 120px;
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 5px;
  }

  .class-list-name {
    font-weight: 700;
    font-size: 16px;
    padding-left: 5px;
    padding-right: 28px;
    text-align: left;
  }

  .info-dev {
    display: flex;
    justify-content: left;
    margin: 13px 0px;
    padding-left: 5px;
  }
}
</style>