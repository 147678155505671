<template>
	<div>
		<div class="header">
			<div class="header-info">
				<div class="career-top">
					<span
						class="yellow-text font-poppins span-career"
						@click="$router.push('/career')"
						>Karir</span
					>
					<div>
						<Arrow
							:width="14"
							:height="14"
							direct="left"
							strokeColor="white"
							class="icon" />
					</div>
					<span class="font-poppins span-career-name">{{
						careerDetail.name
					}}</span>
				</div>
			</div>
			<div class="header-info">
				<div class="career">
					<div class="career-info">
						<div class="career-title">
							<h2>{{ careerDetail.name }}</h2>
						</div>
						<div class="career-info-ctn">
							<span class="font-poppins">{{
								careerDetail.company
							}}</span>
							<span class="font-poppins">{{
								careerDetail.location
							}}</span>
						</div>
					</div>
					<div class="career-data">
						<div class="label">
							<div
								style="background-color: #f1f6ff"
								v-if="careerDetail.contract_type">
								<clock-ic :width="18" :height="18" />
								<p class="label-text">
									{{ careerDetail.contract_type }}
								</p>
							</div>
							<div
								style="background-color: #faeceb"
								v-if="careerDetail.work_type">
								<location-ic :width="18" :height="18" />
								<p class="label-text">
									{{ careerDetail.work_type }}
								</p>
							</div>
							<div
								style="background-color: #faf6e7"
								v-if="careerDetail.level">
								<level-ic :width="18" :height="18" />
								<p class="label-text">
									{{ careerDetail.level }}
								</p>
							</div>
						</div>
					</div>
					<div style="display: flex; gap: 8px; margin-top: 32px">
						<Button
							name="LAMAR PEKERJAAN"
							width="80%"
							height="55px"
							@click="() => toCheckout()" />
						<div class="ic-copy" @click="onCopyUrl">
							<CopyIcon :width="24" :height="24" />
						</div>
					</div>
					<v-alert
						class="alert-info"
						type="success"
						:value="show_alert"
						>Copied</v-alert
					>
					<div class="career-info" style="margin-top: 16px">
						<div class="career-info-ctn">
							<span
								class="font-poppins"
								style="
									font-weight: 400;
									font-size: 14px;
									color: #575966;
								"
								>Ditutup pada
								<b class="font-poppins">{{
									careerDetail.closed_at
								}}</b></span
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			style="
				background-color: #f5f6f9;
				border-radius: 32px 32px 0px 0px;
				padding: 48px 24px 80px 24px;
			">
			<div class="career-detail">
				<div class="career-detail-item">
					<h2>Siapa yang Kami Cari</h2>
					<ul>
						<li
							v-for="(responsibility, idx) in careerDetail
								.career_detail.responsibilities"
							:key="idx"
							class="font-poppins">
							{{ responsibility }}
						</li>
					</ul>
				</div>
				<div class="career-detail-item">
					<h2>Apa Saja yang Akan Kamu Lakukan</h2>
					<ul>
						<li
							v-for="requirement in careerDetail.career_detail
								.requirements"
							:key="requirement"
							class="font-poppins">
							{{ requirement }}
						</li>
					</ul>
				</div>
				<div style="display: flex; justify-content: center">
					<Button
						name="LAMAR PEKERJAAN"
						width="250px"
						height="50px"
						@click="() => toCheckout()" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Button from '@/components/others/Button.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import CopyIcon from '@/assets/icons/career/Copy.vue';
import ClockIc from '@/assets/icons/career/Clock.vue';
import LevelIc from '@/assets/icons/career/Level.vue';
import LocationIc from '@/assets/icons/career/Location.vue';
export default {
	name: 'MobileCareerDetail',
	components: {
		Button,
		ClockIc,
		LevelIc,
		LocationIc,
		Arrow,
		CopyIcon,
	},
	data() {
		return {
			careerDetail: {},
			show_alert: false,
		};
	},
	watch: {
		show_alert() {
			if (!this.show_alert) return;
			setTimeout(() => {
				this.show_alert = false;
			}, 3000);
		},
	},
	methods: {
		async getCareerDetail() {
			await this.$http
				.get(`/v1/career/${this.$route.params.id}`)
				.then((response) => {
					this.careerDetail = response.data.data;
					const options = {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					};
					const formatter = new Intl.DateTimeFormat('id-ID', options);

					const startDate = new Date(this.careerDetail.started_at);
					this.careerDetail.started_at = formatter.format(startDate);

					const closedAt = new Date(this.careerDetail.closed_at);
					this.careerDetail.closed_at = formatter.format(closedAt);
				})
				.catch((error) => {
					console.error('Error fetching data:', error);
					this.$router.push('/not-found');
				});
		},
		onCopyUrl() {
			const clipboardData =
				event.clipboardData ||
				window.clipboardData ||
				event.originalEvent?.clipboardData ||
				navigator.clipboard;
			clipboardData.writeText(window.location.href);
			this.show_alert = true;
		},
		toCheckout() {
			if (new Date() > new Date(this.careerDetail.closed_at)) return;
			this.$router.push(`/career/applicant/${this.careerDetail.id}`);
		},
	},
	mounted() {
		// Make a GET request to the API endpoint
		this.getCareerDetail();
	},
};
</script>

<style src="./detail.css" scoped></style>
