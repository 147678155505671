<template>
  <div style="margin-top: 45px; background-color: #F5F6F9;">
    <div class="container-banner">
      <img :src="classDetail.image_url" class="img-banner" />
      <div class="left-banner">
        <div class="label-list">
            <div class="btn-white-oval font-poppins">{{ classDetail.level }}</div>
            <div class="btn-white-oval font-poppins">{{ classDetail.method | toMethod }}</div>
        </div>
        <div style="margin-top: 24px; padding: 0 10px;">
          <span class="text-banner">{{ classDetail.name }}</span>
        </div>
        <div class="subtext-banner">
          <p>Kembangkan kompetensi kerja & kewirausahaan dengan ikut Kelas Prakerja HiColleagues sebagai mitra resmi Lembaga Pelatihan Kartu Prakerja <span>#SiapDariSekarang</span></p>
        </div>
        <div class="button-banner-container">
          <Button name="DAFTAR SEKARANG" @click="() => goToCheckoutPage()" :bold="true" width="90%" style="background-color: #FFDE59; color: #36373F; font-size: 16px; padding: 15px 0;" />
          <Button name="KONSULTASI GRATIS" @click="() => sendToWa()" :bold="true" width="90%" style="background-color: white; color: #162CA2; font-size: 16px; padding: 15px 0;" />
        </div>
        <div class="collaboration-banner-container">
          <p style="margin: 0;">Kolaborasi dengan</p>
          <img src="/img/home/label-prakerja.png" alt="prakerja">
        </div>
      </div>
    </div>
    <div class="section-container">
      <span class="font-poppins">Overview</span>
      <h5 class="title">{{ classDetail.name }}</h5>
      <p class="desc">{{ classDetail.description }}</p>
    </div>
    <div class="box-steps" style="padding: 64px 0 !important;">
      <div class="box-steps-ctn py-4" style="display: flex;" @click="isDetailClass = !isDetailClass">
        <h5 class="title">Detail Kelas</h5>
        <ArrowIc :down="!isDetailClass" />
      </div>
      <div class="box-steps-ctn" v-if="isDetailClass">
        <p v-html="classDetail.learning_objective"></p>
        <h3 class="my-3">Sasaran Peserta</h3>
        <ul>
          <li v-for="(item, idx) in classDetail.target_audience" :key="idx" class="font-poppins">{{ item.level }}</li>
        </ul>
        <h3 class="my-3">Metode Penyampaian</h3>
        <p v-html="classDetail.learning_method"></p>
        <h3 class="my-3">Perangkat Evaluasi</h3>
        <ul>
          <li v-for="(item, idx) in evaluasi" :key="idx" class="font-poppins">{{ item }}</li>
        </ul>
        <h3 class="my-3">Syarat & Ketentuan Mengikuti Kelas</h3>
        <ul>
          <li v-for="(item, idx) in classDetail.requirements" :key="idx" class="font-poppins">{{ item.name }}</li>
        </ul>
        <div class="btn-hide" @click="isDetailClass = !isDetailClass">
          <span>SEMBUNYIKAN</span>
          <arrow-ic />
        </div>
      </div>
    </div>
    <div class="section-container" style="background-color: #F1F6FF; padding-bottom: 80px">
      <span class="font-poppins">Materi Silabus</span>
      <h5 class="title">Apa saja yang Peserta Pelajari</h5>
      <div class="syllabus-summary">
        <p>{{ classDetail.syllabuses.length }} Topik Materi &bull; {{ 17 }} Pelajaran &bull; {{ 9 }} Jam Total Durasi</p>
      </div>
      <div v-for="(item, idx) in classDetail.syllabuses" :key="idx" class="box-steps" style="margin-top: 28px;">
        <div class="box-steps-ctn pb-3" style="display: flex;">
          <div>
            <span class="font-poppins">Sesi {{ idx + 1 }}</span>
            <h4 v-for="(item2, idx2) in item.name" :key="idx2">{{ item2 }}</h4>
            <p style="margin-top: 6px;">&bull; {{ 8 }} Pelajaran &bull; {{ 36 }} Menit</p>
          </div>
        </div>
        <div class="box-steps-ctn border-top pa-5" v-if="item.show">
          <div class="list-art" v-if="idx === 0">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Pre-Test</p>
              <p style="color: #7B7E8C">Initial Exam</p>
            </div>
          </div>
          <div class="list-art">
            <img src="/img/icons/easel.png" alt="File">
            <div class="info-ars">
              <p>Live Session : {{ item.name[0] }}</p>
              <p style="color: #7B7E8C">Live & Share Deck</p>
            </div>
          </div>
          <div class="list-art">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Formative Test</p>
              <p style="color: #7B7E8C">Quiz {{ idx + 1 }}</p>
            </div>
          </div>
          <div class="list-art" v-if="idx < 4">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Tugas Praktik Mandiri {{ idx + 1 }}</p>
              <p style="color: #7B7E8C">Individual Assignment</p>
            </div>
          </div>
          <div class="list-art" v-if="idx >= classDetail.syllabuses.length - 1">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Post-Test</p>
              <p style="color: #7B7E8C">Final Exam</p>
            </div>
          </div>
          <div class="list-art" v-if="idx >= classDetail.syllabuses.length - 1">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Unjuk Keterampilan</p>
              <p style="color: #7B7E8C">Individual Assignment</p>
            </div>
          </div>
        </div>
        <div class="btn-syllabus-hide" @click="item.show = !item.show">
          <ArrowIc :down="!item.show" />
        </div>
      </div>
    </div>

    <div class="section-container" style="margin-top: 30px;">
      <h5 class="title">Apa Saja yang HiColers Dapatkan?</h5>
      <BenefitPrakerja style="margin-top: 32px" />
    </div>

    <div class="section-container" style="margin-top: -40px;">
      <span>Instruktur</span>
      <h5 class="title">Mentor dalam Pelatihan Ini</h5>
      <div class="instructor">
        <img :src="$utils.loadImage(instructor.image_url)" alt="">
        <div class="instructor-detail">
          <h5 class="title">{{ instructor.name }}</h5>
          <p>Instructor</p>
        </div>
      </div>
      <p class="instructor-description">{{ instructor.description }}</p>
    </div>

    <!-- <div class="section-container" style="margin-top: -40px;">
      <span>Jadwal & Biaya Penelitian</span>
      <h5>Jadwal Kelas yang Dapat HiColers Ikuti</h5>
      <div class="schedule">
        <h3 class="schedule-title">{{ classDetail.name }}</h3>
        <div class="schedule-list">
          <div class="schedule-item">
            <Date fill="#7B7E8C" :width="24" :heigth="24" />
            <div class="schedule-detail">
              <p class="schedule-date">Senin, 1/4/2024 - Jumat, 5/4/2024</p>
              <p class="schedule-hour">(19.00 - 22.00 WIB)</p>
              <p class="schedule-quota quota-full">Kuota Penuh</p>
            </div>
          </div>
          <div class="schedule-item">
            <Date fill="#7B7E8C" :width="24" :heigth="24" />
            <div class="schedule-detail">
              <p class="schedule-date">Senin, 1/4/2024 - Jumat, 5/4/2024</p>
              <p class="schedule-hour">(19.00 - 22.00 WIB)</p>
              <p class="schedule-quota">Tersedia</p>
            </div>
          </div>
          <div class="schedule-item">
            <Date fill="#7B7E8C" :width="24" :heigth="24" />
            <div class="schedule-detail">
              <p class="schedule-date">Senin, 1/4/2024 - Jumat, 5/4/2024</p>
              <p class="schedule-hour">(19.00 - 22.00 WIB)</p>
              <p class="schedule-quota">Tersedia</p>
            </div>
          </div>
          <div class="schedule-item">
            <Date fill="#7B7E8C" :width="24" :heigth="24" />
            <div class="schedule-detail">
              <p class="schedule-date">Senin, 1/4/2024 - Jumat, 5/4/2024</p>
              <p class="schedule-hour">(19.00 - 22.00 WIB)</p>
              <p class="schedule-quota">Tersedia</p>
            </div>
          </div>
          <div class="class-schedule-detail">
            <div class="class-schedule-detail-level">
              <p class="class-schedule-detail-key">Level Pelatihan</p>
              <p class="class-schedule-detail-value">{{ classDetail.level }}</p>
            </div>
            <div class="class-schedule-detail-quota">
              <p class="class-schedule-detail-key">Kuota Peserta</p>
              <p class="class-schedule-detail-value">{{ 50 }}</p>
            </div>
            <div class="class-schedule-detail-session">
              <p class="class-schedule-detail-key">Sesi Pelatihan</p>
              <p class="class-schedule-detail-value">{{ classDetail.session_total }}</p>
            </div>
            <div class="class-schedule-detail-method">
              <p class="class-schedule-detail-key">Metode Pelatihan</p>
              <p class="class-schedule-detail-value">{{ classDetail.method | toMethod }}</p>
            </div>
          </div>
          <div class="confirm-purchase-class">
            <h3>Sudah Beli Kelas dari Mitra Digital Platform Kami?</h3>
            <p>Apabila HiColers sudah membeli kelas pelatihan ini, segera bergabung dengan menukarkan Kode Voucher dari Mitra Platform Digital</p>
            <Button name="DAFTAR SEKARANG" @click="() => goToCheckoutPage()" :bold="true" width="100%" style="background-color: #162CA2; color: white; font-size: 16px; padding: 15px 0; margin-top: 32px;" />
          </div>
          <p class="question-to-purchase" @click.prevent="() => goToCheckoutPage()">Belum Beli Kelas?</p>
          <div class="class-price-box">
            <h5 class="class-price-box-header">Harga Reguler</h5>
            <div class="class-price-box-body">
              <h5>{{ formatIDR(classDetail.price) }}</h5>
              <p>Pembelian hanya dapat dilakukan dengan insentif Kartu Prakerja</p>
            </div>
          </div>
          <div class="prakerja-partner">
            <p>Kelas pelatihan ini tersedia di Mitra Platform Digital</p>
            <div class="prakerja-partner-img-list">
              <div class="ctn-img-prakerja">
                <img src="/img/prakerja/tokopedia.png" class="img-prakerja-partner" alt="">
              </div>
              <div class="ctn-img-prakerja">
                <img src="/img/prakerja/pintar.png" class="img-prakerja-partner" alt="">
              </div>
              <div class="ctn-img-prakerja">
                <img src="/img/prakerja/bukalapak.png" class="img-prakerja-partner" alt="">
              </div>
              <div class="ctn-img-prakerja">
                <img src="/img/prakerja/pijar.png" class="img-prakerja-partner" alt="">
              </div>
              <div class="ctn-img-prakerja">
                <img src="/img/prakerja/kariermu.png" class="img-prakerja-partner" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-container" style="margin-top: -40px;">
      <span class="font-poppins">Testimoni</span>
      <h5 class="title">Berbagai Cerita Alumni Kartu Prakerja HiColleagues</h5>
      <div class="testimony">
        <div class="testimony-item">
          <p class="textimony-text">“Pengalaman kolaborasi yang menyenangkan bersama HiColleagues dalam suksesnya penyelenggaraan event yang berdampak positif terhadap perkembangan bisnis kami.”</p>
          <div class="testimony-profile">
            <img src="/img/prakerja/Sabila Bunga 1.png" alt="">
            <div class="testimony-profile-detail">
              <h5>Sabila Bunga Dariana</h5>
              <p>Cirebon</p>
            </div>
          </div>
        </div>
        <div class="testimony-item">
          <p class="textimony-text">“Suasana pelatihan yang sangat humble, materi mudah dipahami karena mentor menyampaikan dengan baik dan terlihat penuh pengalaman. Hal ini menjadi bekal dalam penguasaan prinsip dasar untuk mengawali karir baru di masa depan."</p>
          <div class="testimony-profile">
            <img src="/img/prakerja/Muhammad Ihsan 1.png" alt="">
            <div class="testimony-profile-detail">
              <h5>Muhammad Ihsan</h5>
              <p>Jember</p>
            </div>
          </div>
        </div>
        <div class="testimony-item">
          <p class="textimony-text">“Suasana pelatihan yang sangat humble, materi mudah dipahami karena mentor menyampaikan dengan baik dan terlihat penuh pengalaman. Hal ini menjadi bekal dalam penguasaan prinsip dasar untuk mengawali karir baru di masa depan.”</p>
          <div class="testimony-profile">
            <img src="/img/prakerja/Agus Santoso 1.png" alt="">
            <div class="testimony-profile-detail">
              <h5>Agus Santoso</h5>
              <p>Lampung</p>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-stoke-blue cursor-pointer mt-10" @click="$router.push('/faq')" >
        <span class="font-poppins">LIHAT SELENGKAPNYA</span>
        <ArrowLong />
      </div>
    </div>
    <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="container">
          <AccordeonFaq
            v-for="(item, index) in questions"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          />
          <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')" style="background-color: white; margin-top: 30px;">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import ArrowIc from "@/assets/icons/Arrow.vue";
import BenefitPrakerja from "@/components/prakerja/BenefitPrakerja.vue";
import Button from "../../others/Button.vue";
import moment from "moment";
// import Date from "../../../assets/icons/DateIc2.vue";
// import ArrowLong from "../../../assets/icons/ArrowLong.vue";
// import AccordeonFaq from "../../home/AccordeonFaq.vue";

export default {
  components: {
    BenefitPrakerja,
    Button,
    ArrowIc,
    // Date,
    // ArrowLong,
    // AccordeonFaq,
  },
  data() {
    return {
      publicClass: [
        { message: "Lebih dari satu peserta (max 10)" },
        { message: "Jadwal kelas telah ditentukan" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      privateClass: [
        { message: "Hanya satu peserta" },
        { message: "Dapat menentukan jadwal kelas" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      evaluasi: [
        "Pre-Test",
        "Kuis",
        "Tugas Praktik Mandiri",
        "Post-Test",
        "Uji Keterampilan"
      ],
      faq: [
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
      ],
      menuPublicClass: [
        { name: "Info Kelas", id: 1 },
        { name: "Materi Silabus", id: 2 },
        { name: "Benefit", id: 3 },
        { name: "Path Career", id: 4 },
        { name: "Biaya Kelas", id: 5 },
        { name: "Alur Pendaftaran", id: 6 },
        { name: "FAQ", id: 7 },
      ],
      isDetailClass: true,
      classDetail: {
        requirements: [],
      },
      instructor: {},
      questions: [
        {
          title: "Bagaimana mekanisme pelatihan yang dilakukan secara luring atau offline?",
          description: "Pelatihan metode luring/offline dilakukan pada lokasi yang telah disepakati bersama, dengan didampingi oleh staff HiColleagues peserta dan instruktur dapat fokus menjalankan pelatihan dengan maksimal.",
          active: true
        },
        {
          title: "Bagaimana mekanisme pelatihan yang dilakukan secara luring atau offline?",
          description: "Pelatihan metode luring/offline dilakukan pada lokasi yang telah disepakati bersama, dengan didampingi oleh staff HiColleagues peserta dan instruktur dapat fokus menjalankan pelatihan dengan maksimal.",
          active: true
        },
        {
          title: "Apa saja yang dipersiapkan untuk menjalin kemitraan Corporate Service dengan HiColleagues?",
          description: "Pelatihan metode luring/offline dilakukan pada lokasi yang telah disepakati bersama, dengan didampingi oleh staff HiColleagues peserta dan instruktur dapat fokus menjalankan pelatihan dengan maksimal.",
          active: true
        },
      ]
    };
  },
  methods: {
    goToCheckoutPage() {
      var classType = "";
      if (this.$route.query.tipe_kelas == "prakerja") {
        classType = "PRAKERJA_CLASS";
      } else if (this.$route.query.tipe_kelas == "public-bootcamp") {
        classType = "BOOTCAMP_CLASS";
      }

      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: classType,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });

      localStorage.clear();
      localStorage.setItem("checkout", req);
      window.location.href = "/checkout";
    },
    sendToWa() {
      window.open('https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya');
    },
    formatIDR(amount) {
      return "IDR " + amount.toLocaleString("id-ID");
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slugname)
        .then((response) => {
          this.classDetail = {
            ...response.data.data.class_detail,
            syllabuses: response.data.data.class_detail.syllabuses.map(item => ({
              ...item,
              name: item.description.split("<li>").map(item2 => item2.replace("</ul>", "").replace("</li>", "")),
              show: true,
            }))
          };
          this.classDetail.syllabuses = this.classDetail.syllabuses.map(item => {
            item.name.splice(0, 1)
            return {
              ...item,
              name: item.name
            }
          })
          this.instructor = response.data.data.instructor
          // this.getSchedule(this.classDetail.session_total, this.classDetail.category_id)
        })
        .catch(() => {
          this.$router.push('/prakerja')
        });
      // .finally(() => this.spin_load.val = false)
    },
    formatDate(date) {
      return moment(date).format("dddd, DD/MM/YYYY");
    },
    toMethode(value) {
      if (!value) return
      if (value.toLowerCase() === "online") {
        return "Daring (via Zoom)"
      } else if (value.toLowerCase() === "offline") {
        return "Luring"
      } else {
        return value
      }
    },
  },
  mounted() {
    this.getDetailClass();
  },
  // computed: {
  //   totalMateri() {
  //     return this.classDetail.syllabuses.reduce((total, s) => total + s.materi.length, 0);
  //   }
  // },
  filters: {
    toMethod(value) {
      return value === "Online" ? "Daring (via Zoom)" : value;
    }
  }
};
</script>

<style src="./detailClass.css" scoped></style>