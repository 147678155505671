<template>
  <div class="bg-success">
    <div class="s-card">
      <div class="text-center">
        <img src="/img/other/success_notification.png" style="width: 100%" />
      </div>
      <div class="text-center font-weight-bold mt-10">
        <p style="font-size: 24px; max-width: 410px; margin: auto">
          Lamaran Kerja Terkirim!
        </p>
      </div>
      <div class="text-center mt-5 mb-10">
        <span style="font-size: 1rem" class="font-poppins"
          >Selamat lamaran kerja Anda telah berhasil terkirim. Informasi
          selanjutnya akan kami hubungi melalui Email.</span
        >
      </div>
      <div class="text-center mt-5 d-flex" style="justify-content: center">
        <Button
          name="MENGERTI"
          @click="() => goToHomePage()"
          width="270px"
          height="47px"
        />
      </div>
      <div class="text-center mt-10 mb-2">
        <span style="font-size: 1rem" class="font-poppins"
          >Jika ada pertanyaan lebih lanjut, Anda dapat menghubungi kami melalui
          :</span
        >
      </div>
      <p style="text-align: center" class="mt-5">
        Whatsapp: <b class="font-poppins">(+62) 821-2727-4632</b>
      </p>
      <div></div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/others/Button.vue";
export default {
  components: {
    Button,
  },
  data() {
    return {
      mobileView: false,
    };
  },
  methods: {
    goToHomePage() {
      this.$router.push("/event");
    },
  },
  mounted() {
    this.mobileView = window.innerWidth <= 990;
  },
};
</script>
<style scoped>
.s-card {
  padding: 40px 60px;
  width: 40%;
  margin: auto;
  background-color: white;
  border-radius: 36px;
}
.bg-success {
  background-color: rgb(243, 240, 240);
  padding: 110px 0;
  margin-top: 50px;
}
@media screen and (max-width: 900px) {
  .s-card {
    width: 90%;
    padding: 30px 18px;
  }
}
</style>
