<template>
  <div style="margin-top: 45px">
    <!-- <div class="bg">
            <div class="left-banner">
                <div>
                    <img :src="classDetail.image_url" class="img-banner">
                    <div><span class="text-banner">{{classDetail.name}}</span></div>
                    <div class="d-flex" style="justify-content:center;margin-top:10px;">
                        <div class="btn-white-oval-l">Intensive Class</div>
                        <div class="btn-white-oval-l">Boost Further</div>
                    </div>
                </div>
                <p style="margin-top:30px;font-size:14px;">Tingkatkan skill menjadi profesional Programmer dengan Job Guarantee Program dan dapatkan unlimited career support sampai kamu diterima bekerja.</p>

                <v-btn depressed color="#162CA2" @click="goToCheckoutPage" style="margin-top:58px;padding:25px 95px;" class="btn-radius">
                    <span class="text-capitalize font-weight-bold white--text" @click="goToCheckoutPage">DAFTAR SEKARANG</span>
                </v-btn>
            </div>
        </div> -->
    <img :src="classDetail.image_url" class="img-banner" />
    <div class="left-banner">
      <div class="d-flex" style="justify-content: center; margin-top: 10px">
        <div class="btn-white-oval-l">Intensive Class</div>
        <div class="btn-white-oval-l">Boost Further</div>
      </div>
      <div style="margin-top: 10px">
        <span class="text-banner">{{ classDetail.name }}</span>
      </div>
      <p style="font-size: 14px">
        Tingkatkan skill menjadi profesional Programmer dengan Job Guarantee
        Program dan dapatkan unlimited career support sampai kamu diterima
        bekerja.
      </p>
      <div style="width: 90%; margin: auto">
        <button-yellow name="DAFTAR SEKARANG" @click="() => goToCheckoutPage()" width="100%" height="50px" />
      </div>
    </div>
    <div style="padding: 0px 16px; margin-top: -20px">
      <span>Info Kelas</span>
      <h5 class="title">Deskripsi Program</h5>
      <p class="desc">{{ classDetail.description }}</p>

      <h5 class="title" style="margin-top: 40px; margin-bottom: 20px">
        Mekanisme Kelas
      </h5>
      <div>
        <div class="stroke-yellow-box d-flex">
          <div class="book">
            <Grow />
          </div>
          <div>
            <div class="title-mechanism"><span>Level</span></div>
            <div class="sub-title-mechanism">
              <span>{{ classDetail.level }}</span>
            </div>
          </div>
        </div>

        <div class="stroke-yellow-box d-flex">
          <div class="book">
            <Methode />
          </div>
          <div>
            <div class="title-mechanism"><span>Metode</span></div>
            <div class="sub-title-mechanism"><span>Online / Offline</span></div>
          </div>
        </div>

        <div class="stroke-yellow-box d-flex">
          <div class="book">
            <Date />
          </div>
          <div>
            <div class="title-mechanism"><span>Sesi Pertemuan</span></div>
            <div class="sub-title-mechanism">
              <span>{{ classDetail.session_total }} sesi</span>
            </div>
          </div>
        </div>

        <div class="stroke-yellow-box d-flex">
          <div class="book">
            <Clock />
          </div>
          <div>
            <div class="title-mechanism"><span>Hari & Jam Pertemuan</span></div>
            <div class="sub-title-mechanism">
              <span>{{ classDetail.days }}<br />{{ classDetail.hours }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="materi-section">
      <span>Materi Silabus</span>
      <h5 class="title">Apa yang kamu pelajari</h5>

      <!-- <v-list style="background: #f1f6ff; margin-bottom: 65px">
        <v-list-group
          v-for="item in classDetail.syllabuses"
          :key="item.session"
          no-action
          active-class="bg-rounded"
          class="bg-rounded"
        >
          <template v-slot:activator>
            <v-list-item-content
              class="font-weight-bold"
              style="margin-bottom;color: #36373f;"
            >
              <v-list-item-title
                class="custom-text-size"
                v-text="item.session"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

<v-list-item-content style="padding-left: 15px; color: #36373f">
  <v-list-item-title class="custom-desc-text-size" v-html="item.description"></v-list-item-title>
</v-list-item-content>
</v-list-group>
</v-list> -->
      <div v-for="item in classDetail.syllabuses" :key="item.session" class="box-steps">
        <h4>{{ item.session }}</h4>
        <div v-html="item.description"></div>
      </div>
    </div>

    <div style="padding: 0px 16px; margin-top: 8px; margin-bottom: 40px">
      <span>Benefit</span>
      <h5 class="title">Apa Saja yang Peserta Dapatkan</h5>

      <BenefitPublicTraining style="margin-bottom: 58px" />

      <span>Tujuan Pembelajaran</span>
      <h5 class="title">Goal pelatihan yang didapatkan</h5>
      <p style="margin-top: 30px; margin-bottom: 58px" v-html="classDetail.learning_objective"></p>

      <span>Target Peserta</span>
      <h5 class="title">Kelas ini Cocok untuk</h5>
      <ul>
        <li v-for="(data, idx) in classDetail.target_audience" :key="idx">{{ data.level }}</li>
      </ul>
    </div>

    <div class="fee-section">
      <span>Biaya Kelas</span>
      <h5 class="title">Pilih Tipe Kelas sesuai Minatmu</h5>

      <v-row class="mt-5">
        <v-col>
          <v-card class="black--text class-type-div" style="padding: 24px">
            <div class="class-name" style="text-align: center">
              <span style="font-weight: 700; font-size: 17px">
                KELAS PUBLIC
              </span>
              <img src="/img/other/purple-line.png" width="108" class="line" />
            </div>
            <div v-for="n in publicClass" :key="n.message" style="display: flex; margin-top: 17px">
              <img src="/img/other/ic_checklist.png" width="25px" height="25px" />
              <span class="text-class-type">{{ n.message }}</span>
            </div>

            <h4 style="margin-top: 58px; margin-bottom: 5px; text-align: center">
              Hanya {{ classDetail.price | toCurrency }}
            </h4>
            <v-btn depressed color="#FFDE59" @click="goToCheckoutPage" style="padding: 20px 105px; width: 100%"
              class="btn-radius">
              <span class="text-capitalize font-weight-bold" style="color: #162ca2">LIHAT KELAS</span>
            </v-btn>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="black--text class-type-div" style="padding: 24px">
            <div class="class-name" style="text-align: center">
              <span style="font-weight: 700; font-size: 17px">
                KELAS PRIVATE
              </span>
              <img src="/img/other/red-line.png" width="108" class="line" />
            </div>
            <div v-for="n in privateClass" :key="n.message" style="display: flex; margin-top: 17px">
              <img src="/img/other/ic_checklist.png" width="25px" height="25px" />
              <span class="text-class-type">{{ n.message }}</span>
            </div>

            <h4 style="margin-top: 58px; margin-bottom: 5px; text-align: center">
              Hanya {{ classDetail.price | toCurrency }}
            </h4>
            <v-btn depressed color="#162CA2" @click="goToCheckoutPage" style="padding: 20px 105px; width: 100%"
              class="btn-radius">
              <span class="text-capitalize font-weight-bold white--text">LIHAT KELAS</span>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div style="padding: 30px 16px">
      <span>Alur Pendaftaran</span>
      <h5 class="title">Satu Langkah Menuju Impianmu</h5>

      <StepOfPublicTraining />
    </div>
    <div style="padding: 30px 16px">
      <span>Instruktur</span>
      <h5 class="title">Tentang Instruktur</h5>
      <div class="instructor">
        <img :src="$utils.loadImage(instructor.image_url)" alt="" />
        <h5 class="title">{{ instructor.name }}</h5>
        <p>Instructor</p>
      </div>
      <p style="text-align: justify">{{ instructor.description }}</p>
    </div>
    <div class="bg-gradient-blue" style="height: 300px">
      <div style="font-weight: 600; font-size: 16px; color: #fff">
        <span>Siap Akselerasi Transformasi Digital <br />
          melalui Profesional IT Talent bersama HiColleagues?</span>
      </div>
      <div style="
          display: flex;
          justify-content: center;
          align-content: center;
          margin-top: 40px;
        ">
        <button-yellow name="DAFTAR SEKARANG" @click="() => goToCheckoutPage()" width="70%" height="50px" />
      </div>
    </div>
  </div>
</template>

<script>
import Grow from "@/assets/icons/Grow.vue";
import Methode from "@/assets/icons/Methode.vue";
import Date from "@/assets/icons/Date.vue";
import Clock from "@/assets/icons/Clock.vue";
import BenefitPublicTraining from "@/components/public-training/BenefitPublicTraining";
import StepOfPublicTraining from "@/components/public-training/StepOfPublicTraining";
// import StepOfPrakerja from "@/components/PublicTraining/StepOfPrakerja";
import ButtonYellow from "../../others/ButtonYellow.vue";
export default {
  components: {
    BenefitPublicTraining,
    StepOfPublicTraining,
    // StepOfPrakerja,
    ButtonYellow,
    Grow,
    Methode,
    Date,
    Clock,
  },
  data() {
    return {
      publicClass: [
        { message: "Lebih dari satu peserta (max 10)" },
        { message: "Jadwal kelas telah ditentukan" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      privateClass: [
        { message: "Hanya satu peserta" },
        { message: "Dapat menentukan jadwal kelas" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      faq: [
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
      ],
      menuPublicClass: [
        { name: "Info Kelas", id: 1 },
        { name: "Materi Silabus", id: 2 },
        { name: "Benefit", id: 3 },
        { name: "Path Career", id: 4 },
        { name: "Biaya Kelas", id: 5 },
        { name: "Alur Pendaftaran", id: 6 },
        { name: "FAQ", id: 7 },
      ],
      classDetail: {},
      instructor: {},
    };
  },
  methods: {
    goToCheckoutPage() {
      var classType = "";
      if (this.$route.query.tipe_kelas == "prakerja") {
        classType = "PRAKERJA_CLASS";
      } else if (this.$route.query.tipe_kelas == "public-bootcamp") {
        classType = "BOOTCAMP_CLASS";
      }

      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: classType,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });

      localStorage.clear();
      localStorage.setItem("checkout", req);
      window.location.href = "/checkout";
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slugname)
        .then((response) => {
          this.classDetail = response.data.data.class_detail;
          this.instructor = response.data.data.instructor;
          if (this.classDetail.category_id === 5) {
            this.$router.push("/class/bootcamp/" + this.$route.params.slugname);
          }
        })
        .catch(() => {
          this.$router.push('/class/public-training')
        });
    },
  },
  mounted() {
    this.getDetailClass();
  },
};
</script>

<style src="./css/detailClass.css" scoped></style>