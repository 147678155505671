<template>
  <div>
     <DesktopFAQ v-if="!isMobile" />
     <MobileFAQ v-if="isMobile" />
  </div>
</template>

<script>
import DesktopFAQ from '@/components/faq/desktop/FAQ.vue'
import MobileFAQ from '@/components/faq/mobile/FAQ.vue'

export default {
  components: {
    DesktopFAQ,
    MobileFAQ
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = window.innerWidth <= 990
      }
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
}
</script>