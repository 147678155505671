<template>
  <div class="corporate-form">
    <v-alert type="error" class="alert-error" v-if="isError">{{
      messageErr
    }}</v-alert>

    <!-- <PopupError
      :message="alertErrMessage"
      v-if="isAlertErr"
      @close="isAlertErr = false"
    /> -->
    <div
      class="back-icon"
      @click="$router.push('/corporate')"
      v-if="!mobileView"
    >
      <round-arrow-back :right="true" />
    </div>
    <div class="nav-form" v-else>
      <div class="back-icon-mobile" @click="$router.push('/corporate')">
        <round-arrow-back />
      </div>
    </div>
    <div class="form-c">
      <div style="text-align: center; margin-bottom: 50px">
        <h1>Form Pendaftaran</h1>
        <p>Siap transformasi perusahaan dengan peningkatan skill karyawan.</p>
      </div>
      <div class="input-box">
        <span class="font-poppins"
          >Nama Perusahaan
          <b class="font-poppins" style="font-weight: normal">(Wajib)</b></span
        >
        <v-text-field
          v-model="corporateName"
          :rules="[() => !!corporateName || 'This field is required']"
          outlined
          class="poppins-font mt-2 rounded-sm"
          placeholder="Masukkan Nama Perusahaan Anda"
          dense
        ></v-text-field>
      </div>
      <div class="input-box">
        <span class="font-poppins">Perusahaan bergerak dibidang</span>
        <v-text-field
          v-model="corporateField"
          :rules="[() => !!corporateField || 'This field is required']"
          outlined
          placeholder="Masukkan Bidang Bisnis Perusahaan Anda"
          class="poppins-font mt-2 rounded-sm"
          dense
          required
        ></v-text-field>
      </div>
      <div class="input-box">
        <span class="font-poppins">Title PIC</span>
        <v-radio-group row v-model="gender" required class="poppins-font">
          <v-radio
            v-for="(item, idx) in genders"
            :key="idx"
            :label="item"
            :value="item"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="input-box">
        <span class="font-poppins"
          >Nama Lengkap PIC <b class="font-poppins" style="font-weight: normal">(Wajib)</b></span
        >
        <v-text-field
          v-model="fullName"
          :rules="[() => !!fullName || 'This field is required']"
          outlined
          placeholder="Masukkan Nama Lengkap Anda"
          class="poppins-font mt-2 rounded-sm"
          dense
        ></v-text-field>
      </div>
      <div class="input-box">
        <span class="font-poppins"
          >No. Handphone PIC <b class="font-poppins" style="font-weight: normal">(Wajib)</b></span
        >
        <v-row>
          <v-col cols="3">
            <v-text-field
              label="Kode Negara"
              outlined
              dense
              class="poppins-font mt-2 rounded-sm"
              value="+62"
              disabled
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="phoneNumber"
              :rules="[() => !!phoneNumber || 'This field is required']"
              outlined
              placeholder="812-xxxx-xxxx"
              class="poppins-font mt-2 rounded-sm"
              dense
              type="number"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="input-box font-poppins">
        <span class="font-poppins">Email PIC <b class="font-poppins" style="font-weight: normal">(Wajib)</b></span>
        <v-text-field
          v-model="email"
          :rules="[() => !!email || 'This field is required']"
          outlined
          placeholder="Misal : hicolleagues@email.com"
          class="poppins-font mt-2 rounded-sm"
          type="email"
          dense
        ></v-text-field>
      </div>
      <div class="input-box">
        <span class="font-poppins">Program Pelatihan yang Diinginkan</span>
        <v-autocomplete
          ref="program"
          v-model="program"
          :rules="[() => !!program || 'This field is required']"
          :items="programs"
          placeholder="Pilih Program Pelatihan"
          required
          outlined
          dense
          class="poppins-font mt-2 rounded-sm"
        ></v-autocomplete>
      </div>
      <div class="input-box">
        <span class="font-poppins">Jumlah Karyawan yang Mengikuti Pelatihan</span>
        <v-text-field
          v-model="totalEmployee"
          :rules="[() => !!totalEmployee || 'This field is required']"
          outlined
          class="poppins-font mt-2 rounded-sm"
          dense
          placeholder="Masukkan Jumlah Karyawan"
        ></v-text-field>
      </div>
      <div class="input-box">
        <span class="font-poppins">Catatan</span>
        <v-textarea
          outlined
          placeholder="Tulis di sini ...."
          v-model="notes"
          class="poppins-font mt-2 rounded-sm"
          name="input-7-4"
        ></v-textarea>
      </div>
      <div class="font-poppins" style="max-width: 300px; margin: auto">
        <v-btn
          :class="`poppins-font font-weight-bold pa-6 ${
            validationRequired ? 'btn-terapkan' : 'btn-terapkan-disabled'
          }`"
          @click="submitCorporateRegistration"
        >
          Daftar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import RoundArrowBack from "../../assets/icons/RoundArrowBack.vue";
// import PopupError from "@/components/others/Popup.vue";
export default {
  name: "RegistrasionForm2",
  data() {
    return {
      alertErrMessage: "",
      isAlertErr: false,
      isError: false,
      messageErr: "",
      gender: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      corporateName: "",
      corporateField: "",
      program: "",
      totalEmployee: 1,
      notes: "",
      programs: ["Bootcamp", "Upskilling"],
      genders: ["Tuan", "Nyonya", "Nona"],
      
    };
  },
  components: {
    RoundArrowBack,
    // PopupError,
  },
  watch: {
    isError() {
      if (!this.isError) return;
      setTimeout(() => {
        this.isError = false;
      }, 3000);
    },
  },
  computed: {
    validationRequired() {
      return (
        this.fullName &&
        this.email &&
        this.phoneNumber &&
        this.gender &&
        this.corporateName &&
        this.corporateField &&
        this.program &&
        this.totalEmployee &&
        this.notes
      );
    },
    mobileView() {
      return window.innerWidth <= 990;
    },
  },
  methods: {
    backToPreviousPage() {
      history.go(-1);
    },
    async submitCorporateRegistration() {
      await this.$http
        .post("/v1/corporate-registration", {
          full_name: this.fullName,
          email: this.email,
          phone_number: this.phoneNumber,
          gender: this.gender,
          corporate_name: this.corporateName,
          company_field: this.corporateField,
          program: this.program,
          total_student: Number(this.totalEmployee),
          notes: this.notes,
        })
        .then(() => {
          window.location.href = "/registration/success";
        })
        .catch(() => {
          this.isError = true;
          this.messageErr = "All fields must be filled in";
        });
    },
  },
};
</script>
<style scoped>
.corporate-form {
  padding: 60px 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(241, 241, 241);
}
.checkout-title {
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
}
.form-c {
  background-color: white;
  font-family: "Poppins" !important;
  border-radius: 26px !important;
  box-shadow: 1px 4px 5px 2px rgba(13, 28, 113, 0.1);
  padding: 20px 40px;
  width: 50%;
  margin: auto;
}
.input-box span {
  display: block;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: -3px;
}
.rounded-sm {
  border-radius: 11px !important;
}
.btn-terapkan {
  font-size: 17px;
  width: 100%;
  color: #fff;
  border-radius: 13px;
  background-color: #000080 !important;
}
.btn-terapkan-disabled {
  font-size: 17px;
  width: 100%;
  color: #fff;
  border-radius: 13px;
  background-color: #acafbf !important;
}
.alert-error {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  max-width: 650px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 99;
  cursor: pointer;
  color: rgb(203, 203, 203);
}
.back-icon-mobile {
  cursor: pointer;
  color: rgb(203, 203, 203);
}
.nav-form {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  padding: 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background-color: white;
}

.poppins-font ::placeholder {
  font-family: 'Poppins', sans-serif;
}

.poppins-font >>> label{
  font-family: 'Poppins', sans-serif !important;
}

@media screen and (max-width: 900px) {
  .corporate-form {
    padding: 100px 0px;
  }
  .form-c {
    width: 95%;
    padding: 20px;
    margin: auto;
  }
}
</style>
