<template>
  <div style="background-color: #162ca2">
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      :isError="isError"
      @close="isAlertErr = false"
    />
    <div class="mainContainer">
      <img style="" src="/img/corporate/tech-main.png" alt="" />
      <h1>Wujudkan Transformasi Digital melalui Tim IT Profesional</h1>
      <p>
        Perluas skala bisnis dalam dinamika transformasi digital dengan
        Upskilling &/ New Skilling talenta perusahaan melalui layanan Corporate
        Training HiColleagues sebagai mitra terpercaya & kredibel
      </p>
      <div class="tombol">
        <button class="konsultasi" @click="scrollToSection('collab')">
          KOLABORASI SEKARANG
        </button>
        <button class="hubungi" @click="sendToWa">HUBUNGI KAMI</button>
      </div>
    </div>
    <div class="banner-content">
      <h2 class="text-center font-poppins">
        HiColleagues telah Dipercaya Perusahaan Ternama
      </h2>
      <div
        class="d-flex overflow-x-hidden overflow-y-hidden"
        style="height: 100%"
      >
        <v-col
          cols="6"
          v-for="(data, idx) in partners"
          :key="idx"
          class="
            d-flex
            justify-content-center
            align-content-center
            scroll-container
          "
        >
          <v-img :src="data.img_url_mobile" contain />
        </v-col>
        <v-col
          cols="6"
          v-for="(data, idx) in partners"
          :key="idx"
          class="
            d-flex
            justify-content-center
            align-content-center
            scroll-container-2
          "
        >
          <v-img :src="data.img_url_mobile" contain />
        </v-col>
      </div>
    </div>
    <div style="background-color: white">
      <div style="padding: 20px" class="container text-center">
        <h2>Layanan Profesional dalam Transformasi Digital Berkelanjutan</h2>
        <p>
          Perluas kerjasama kemitraan melalui layanan profesional tim IT
          HiColleagues dan berikan dampak ke peningkatan performa bisnis Anda
        </p>
        <div>
          <div class="card-1 mt-8" v-for="(desc1, idx) in descs1" :key="idx">
            <img :src="desc1.img" class="img-2" />
            <h4>{{ desc1.tittle }}</h4>
            <p>{{ desc1.desc }}</p>
          </div>
        </div>
      </div>

      <div style="padding: 0px 20px" class="why-hicol">
        <h3 class="mt-10 text-center mb-3">
          Mengapa Memilih HiColleagues sebagai Mitra Technology Service
        </h3>
        <p style="text-align: center; font-size: 12px">
          HiColleagues telah membuktikan melalui
          <b
            >5+ layanan kerjasama kemitraan perusahaan yang telah
            dilaksanakan</b
          >
          dan memberi dampak positif terhadap akselerasi performa bisnis mitra
        </p>

        <div class="why-item mb-5" v-for="(item, idx) in whyhicol" :key="idx">
          <div
            :style="
              idx < whyhicol.length - 1
                ? 'border-bottom: 1px solid #acafbf; padding-bottom: 30px;'
                : 'padding-bottom: 30px;'
            "
          >
            <img :src="item.icon" alt="" />
            <h4>
              {{ item.title }}
            </h4>
            <p class="mt-2 text-justify" style="font-size: 12px">
              {{ item.desc }}
            </p>
          </div>
        </div>
        <div
          class="card mb-5"
          style="
            object-fit: cover;

            height: 350px;
            display: block;
            overflow: hidden;
            padding: 0;
            border-radius: 30px;
          "
        >
          <img
            src="/img/corporate/tech/main-2.png"
            style="width: 100%"
            alt=""
          />
        </div>
      </div>
      <div style="padding: 0px 20px" class="bg-linear">
        <div class="collaboratif">
          <h2 class="mb-4 text-center">Langkah Kolaboratif Kemitraan</h2>
          <v-col
            style="margin: 20px 0px"
            class="d-flex justify-end align-center"
          >
            <CollaborationCircle :width="24" :height="24" style="z-index: 1" />
            <div
              style="
                height: 1px;
                width: 100%;
                border: 1px dashed grey;
                left: 50%;
                z-index: 0;
              "
            />
          </v-col>
          <div class="desc">
            <div
              class="card-content"
              v-for="(data, index) in steps"
              :key="index"
            >
              <div class="card-img">
                <img
                  :src="data.image_url"
                  width="300"
                  alt=""
                  style="margin-top: -20px"
                />
              </div>
              <div class="desc">
                <h3>{{ data.title }}</h3>
                <p>{{ data.description }}</p>
              </div>
            </div>
          </div>
          <div class="indicator-container">
            <div style="width: 10px; height: 10px"></div>
            <div class="indicator">
              <svg
                :style="{ transform: `translateX(${indicator * 15}px)` }"
                width="25"
                height="10"
                viewBox="0 0 25 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="25" height="10" rx="5" fill="#162CA2" />
              </svg>
              <svg
                :style="{ transform: `translateX(${-indicator2 * 30}px` }"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
              </svg>
              <svg
                :style="{ transform: `translateX(${-indicator3 * 30}px` }"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
              </svg>
            </div>

            <div class="btn-arrow">
              <div class="btn-card">
                <img
                  src="/img/other/corporate-arrow-left.png"
                  alt=""
                  @click="scrollLeftStep"
                />
              </div>
              <div class="btn-card">
                <img
                  src="/img/other/corporate-arrow-right.png"
                  alt=""
                  @click="scrollRightStep"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="project">
        <h1>Proyek yang Telah Berjalan</h1>
        <div>
          <div>
            <div class="img-card">
              <img :src="portfolios[portfoliosModel].image_url" alt="" />
            </div>
            <div class="card-port">
              <div class="content-port">
                <h2>{{ portfolios[portfoliosModel].title }}</h2>
                <p class="short">{{ portfolios[portfoliosModel].program }}</p>
                <p class="desc">
                  {{ portfolios[portfoliosModel].description }}
                </p>
              </div>
            </div>
          </div>

          <!-- Pagination -->
          <v-row
            style="margin: 0px 10px; padding: 0px 10px"
            class="d-flex align-center"
          >
            <v-col cols="8">
              <div class="slider">
                <div
                  class="indicator"
                  :style="{
                    width: `${sliderIndicatorPortfolios}%`,
                    transform: `translateX(${indicatorValuePortfolios}%)`,
                    transition: `transform 0.5s`,
                  }"
                ></div>
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex"
              style="justify-content: flex-end; gap: 11px"
            >
              <v-btn
                v-for="index in 2"
                fab
                :key="index"
                @click="index === 1 ? PortfoliosPrev() : PortfoliosNext()"
                class="btn-mobile-slider-corp"
                elevation="0"
              >
                <ArrowIcon
                  style="color: white"
                  :direct="index === 1 ? 'right' : 'left'"
                />
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>

      <div style="padding: 0px 20px" class="form-konsultasi">
        <div class="header text-center">
          <h2 style="font-weight: 700; line-height: 22px; width: 100%">
            Formulir Pendaftaran Konsultasi
          </h2>
        </div>
        <div class="sub-heading mt-1 text-center">
          <p
            style="
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              text-align: center;
            "
          >
            Langkah tepat optimasi bisnis melalui layanan Corporate Service
            bersama HiColleagues
          </p>
          <v-form class="text-start" style="margin-top: 60px" id="collab">
            <v-col cols="100">
              <label class="form-label" for="company"
                >Nama Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="company"
                v-model="form.corporate_name"
                outlined
                placeholder="Masukkan Nama Perusahaan"
                :full-width="true"
              ></v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="website"
                >Website Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                v-model="form.web_url"
                name="website"
                outlined
                placeholder="Masukkan Alamat Website Perusahaan"
              ></v-text-field>
            </v-col>

            <v-col cols="100">
              <label class="form-label" for="pic-name"
                >Nama Lengkap PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="pic-name"
                v-model="form.full_name"
                outlined
                placeholder="Masukkan Nama Lengkap PIC"
              ></v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="email"
                >Email PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="email"
                outlined
                v-model="form.email"
                placeholder="Masukkan Email PIC"
                max-width
              ></v-text-field>
            </v-col>

            <v-col cols="100">
              <label class="form-label" for="occupation">Okupasi PIC</label>
              <v-text-field
                class="form-input text-form"
                name="occupation"
                outlined
                v-model="form.job_pic"
                placeholder="Masukkan Okupasi PIC"
              >
              </v-text-field>
            </v-col>
            <v-col cols="100">
              <label class="form-label" for="phone-number"
                >No. Whatsapp PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="phone-number"
                outlined
                placeholder="Masukkan No. Whatsapp PIC"
                v-model="form.phone_number"
                type="number"
                hide-spin-buttons
              >
                <template v-slot:prepend-inner>
                  <img
                    src="/img/other/flag-indonesia.png"
                    alt=""
                    width="30"
                    height="20"
                    style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)"
                  />
                  <span style="color: #575966" class="mx-3">+62</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <label class="form-label" for="collaboration"
                >Proyeksi Kolaborasi/Kemitraan</label
              >
              <v-text-field
                class="form-input text-form"
                solo
                flat
                background-color="rgba(247, 249, 253, 1)"
                placeholder="Tech Service"
                disabled
              />
            </v-col>
            <v-row class="d-flex justify-center align-center mt-3">
              <v-checkbox
                style="width: 100%; margin: 0px 20px"
                class="form-input custom-checkbox"
                v-model="is_confirm"
              >
                <template v-slot:label>
                  <h5 class="label-checkbox">
                    Dengan mengirim formulir ini saya menyetujui<a href="#">
                      Syarat & Ketentuan </a
                    >untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                  </h5>
                </template>
              </v-checkbox>
            </v-row>
            <v-row
              style="width: 100%"
              class="d-flex justify-center align-center mt-6"
            >
              <v-btn
                elevation="0"
                :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`"
                @click="onSubmit"
                >KIRIM
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="container">
          <v-expansion-panels flat class="faq-card">
            <v-expansion-panel
              style="border-radius: 20px; margin-bottom: 10px"
              v-for="(item, index) in questions"
              :key="index"
            >
              <v-expansion-panel-header
                disable-icon-rotate
                @click="openFaq(index)"
                style="border-radius: 20px"
              >
                <h3>{{ item.title }}</h3>
                <template v-slot:actions>
                  <div
                    class="icon"
                    v-if="item.active"
                    style="margin-right: 20px"
                  >
                    <Arrow />
                  </div>
                  <div v-else style="margin-right: 20px">
                    <Arrow :down="!item.active" />
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-radius: 0 0 20px 20px">
                <div v-html="item.description"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            class="btn-stoke-blue cursor-pointer mt-5"
            @click="$router.push('/faq')"
          >
            <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            <ArrowLong />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import Popup from "@/components/others/Popup.vue";
import ArrowLong from "@/assets/icons/ArrowLong.vue";
import CollaborationCircle from "@/components/ilustration/CollaborationCircle.vue";
import ArrowIcon from "@/assets/icons/Arrow.vue";
export default {
  components: {
    Arrow,
    ArrowLong,
    Popup,
    CollaborationCircle,
    ArrowIcon,
  },
  data() {
    return {
      descs1: [
        {
          img: "/img/corporate/tech-4.png",
          tittle: "IT Consulting",
          desc: "HiColleagues dapat membantu Anda untuk menentukan dan mengembangkan strategi IT dalam semua lini bisnis melalui konsultan inovatif kami. Tim ahli kami siap membantu dalam menganalisis kebutuhan teknologi bisnis Anda, merancang strategi IT yang efektif, dan memberikan solusi yang disesuaikan untuk meningkatkan efisiensi operasional dan daya saing bisnis.",
        },
        {
          img: "/img/corporate/tech-3.png",
          tittle: "IT Sourcing",
          desc: "HiColleagues memberikan layanan maksimal dengan menghadirkan talenta-talenta digital kompeten sesuai dengan kebutuhan pengembangan produk digital dalam bisnis Anda. Pemenuhan kebutuhan talenta digital ini berjalan efisien dan komprehensif, melalui proses seleksi yang baik untuk menghubungkannya sesuai kualifikasi yang dibutuhkan",
        },
        {
          img: "/img/corporate/tech-2.png",
          tittle: "IT Implementation",
          desc: "Mengimplementasikan dan mengkonfigurasi solusi IT sesuai dengan kebutuhan dan spesifikasi Anda, melakukan pengujian dan validasi menyeluruh untuk memastikan solusi IT berfungsi dengan baik. Dari perencanaan hingga eksekusi, tim kami memastikan setiap tahap implementasi berjalan sesuai dengan kebutuhan bisnis Anda.",
        },
        {
          img: "/img/corporate/tech-1.png",
          tittle: "IT Support",
          desc: "Dapatkan dukungan teknologi yang handal dan responsif setiap saat. Tim kami siap membantu mengatasi berbagai masalah IT, mulai dari troubleshooting, maintenance, hingga upgrade sistem. Pastikan operasional bisnis Anda berjalan tanpa gangguan dengan dukungan penuh dari kami.",
        },
      ],
      whyhicol: [
        {
          icon: "/img/corporate/tech/c-1.png",
          title: "Solusi Teknologi Terdepan",
          desc: "Tingkatkan efisiensi dan produktivitas bisnis Anda dengan beragam solusi teknologi terdepan dari HiColleagues.",
        },
        {
          icon: "/img/corporate/tech/c-2.png",
          title: "Tim IT yang Ahli & Profesional",
          desc: "Tim terbaik kami memiliki pengalaman yang profesional dalam mengimplementasikan solusi teknologi yang kompleks agar bisnis Anda tetap unggul di era digital.",
        },
        {
          icon: "/img/corporate/tech/c-3.png",
          title: "Fleksibilitas & Skalabilitas",
          desc: "Pelayanan HiColleagues dirancang untuk dapat disesuaikan dengan berbagai ukuran tim dan skala bisnis. Kami memastikan setiap industri mendapatkan dukungan IT yang tepat dan efisien.",
        },
        {
          icon: "/img/corporate/tech/c-4.png",
          title: "Keamanan dan Privasi Data yang Terjamin",
          desc: "Layanan HiColleagues mengutamakan keamanan dan privasi data setiap Klien tanpa khawatir tentang risiko keamanan dan terlindungi dari ancaman eksternal.",
        },
        {
          icon: "/img/corporate/tech/c-5.png",
          title: "Mengembangkan Transformasi Digital Berkelanjutan",
          desc: "Layanan HiColleagues berfokus pada pengembangan transformasi digital yang berkelanjutan untuk bisnis Anda.",
        },
        {
          icon: "/img/corporate/tech/c-6.png",
          title: "Optimasi Kinerja Bisnis",
          desc: "Layanan teknologi kami dirancang untuk meningkatkan efisiensi operasional dan produktivitas bisnis Anda.",
        },
      ],
      portfolios: [],
      questions: [],
      partners: [],
      inovation: [
        {
          img: "/img/corporate/tech/w1.png",
          name: "Finansial & Perbankan",
        },
        {
          img: "/img/corporate/tech/w2.png",
          name: "Kesehatan",
        },
        {
          img: "/img/corporate/tech/w3.png",
          name: "Pendidikan",
        },
        {
          img: "/img/corporate/tech/w4.png",
          name: "Manufaktur",
        },
        {
          img: "/img/corporate/tech/w5.png",
          name: "Energi & Sumber Daya",
        },
        {
          img: "/img/corporate/tech/w6.png",
          name: "Pemerintahan",
        },
        {
          img: "/img/corporate/tech/w7.png",
          name: "Media",
        },
        {
          img: "/img/corporate/tech/w8.png",
          name: "Pariwisata",
        },
      ],
      steps: [
        {
          image_url: "/img/corporate/tech/bn1.png",
          title: "Analisis Kebutuhan",
          description:
            "HiColleagues memulai dengan melakukan sesi konsultasi untuk memahami kebutuhan dan tujuan bisnis Anda. Tim kami akan menganalisis tantangan yang Anda hadapi dan mengidentifikasi area yang memerlukan dukungan teknologi.",
        },
        {
          image_url: "/img/corporate/tech/bn2.png",
          title: "Rekomendasi Strategi & Solusi",
          description:
            "Kami menyusun rekomendasi solusi teknologi yang paling sesuai dengan kebutuhan Anda. Rekomendasi ini mencakup pilihan layanan seperti IT Consulting, IT Sourcing, IT Implementation, hingga IT Support.",
        },
        {
          image_url: "/img/corporate/tech/bn3.png",
          title: "Strategi, Timeline, & Achievement",
          description:
            "Setelah rekomendasi disetujui, tim kami bekerja sama dengan Anda untuk merancang solusi yang detail dan disesuaikan. Ini termasuk penyusunan rencana implementasi dan perancangan teknologi yang optimal.",
        },
        {
          image_url: "/img/corporate/tech/bn4.png",
          title: "IT Implementation",
          description:
            "Pengimplementasian dan menjalankan rencana yang telah disusun. HiColleagues memastikan setiap komponen teknologi diimplementasikan dengan baik, sesuai dengan jadwal dan anggaran yang telah ditentukan.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "Evaluasi & Optimalisasi",
          description:
            "Pasca proyek, HiColleagues melakukan evaluasi untuk menilai kinerja solusi yang telah diimplementasikan. memastikan operasional teknologi berjalan tanpa hambatan. Hal ini seperti maintenance rutin, troubleshooting, dan pembaruan sistem.",
        },
      ],
      indicator: 0,
      indicator2: 0,
      indicator3: 0,
      portfoliosModel: 0,
      indicatorValuePortfolios: 0,
      sliderIndicatorPortfolios: 0,
      sliderIndicator: 0,
      indicatorValue: 0,
      alertErrMessage: "",
      isAlertErr: false,
      is_confirm: false,
      isError: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "SERVICES",
        web_url: "",
        job_pic: "",
      },
    };
  },
  methods: {
    PortfoliosNext() {
      if (this.portfoliosModel < this.portfolios.length - 1) {
        this.portfoliosModel += 1;
        this.indicatorValuePortfolios += this.sliderIndicatorPortfolios + 50;
      }

      if (this.indicatorValuePortfolios === 100) {
        return;
      }
    },
    PortfoliosPrev() {
      if (this.portfoliosModel > 0) {
        this.portfoliosModel -= 1;
      }

      if (this.indicatorValuePortfolios === 0) {
        return;
      }

      this.indicatorValuePortfolios -= this.sliderIndicatorPortfolios + 50;
    },
    scrollRightStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft += cardWidth * 1.07;
      if (this.indicator < 2) {
        this.indicator++;
        if (this.indicator2 < 1) {
          this.indicator2++;
        }
      }
      if (this.indicator === 2 && this.indicator3 < 1) {
        this.indicator3++;
      }
    },
    scrollLeftStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft -= cardWidth * 1.07;
      if (this.indicator === 1) {
        this.indicator2--;
      }
      if (this.indicator > 0) {
        this.indicator--;
      }
      if (this.indicator3 < 2 && this.indicator > 0) {
        this.indicator3--;
      }
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=SERVICES");
        this.partners = resp.data.data.logos;
      } catch (err) {
        console.log(err);
      }
    },
    async getPortofolio() {
      try {
        const resp = await this.$http.get(
          "/v1/project-portfolio?category=SERVICES"
        );
        this.portfolios = resp.data.data.project_portfolios;
        this.sliderIndicatorPortfolios =
          100 / resp.data.data.project_portfolios.length + 4;
      } catch (err) {
        console.log(err);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active;
      this.questions.forEach((e) => {
        e.active = false;
      });
      this.questions[idx].active = !valDef;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=SERVICES&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return;
      }
      if (
        !this.form.corporate_name ||
        !this.form.full_name ||
        !this.form.job_pic ||
        !this.form.email ||
        !this.form.phone_number ||
        !this.form.category ||
        !this.form.web_url
      ) {
        this.alertErrMessage = "Harap isi semua form yang tersedia";
        this.isAlertErr = true;
        this.isError = true;
        return;
      }
      try {
        await this.$http.post("/v1/corporate-registration", this.form);
        this.alertErrMessage = "Success";
        this.isAlertErr = true;
        this.isError = false;
        this.clearForm();
      } catch {
        this.alertErrMessage = "Error saat submit data";
        this.isAlertErr = true;
        this.isError = true;
      }
    },
    clearForm() {
      this.is_confirm = false;
      this.form.full_name = "";
      this.form.email = "";
      this.form.phone_number = "";
      this.form.corporate_name = "";
      this.form.category = "";
      this.form.web_url = "";
      this.form.job_pic = "";
    },
  },
  mounted() {
    this.getFaq();
    this.getPortofolio();
    this.getClientLogo();
    this.$gtag.pageview(this.$route);
  },
  beforeMount() {
    this.sliderIndicator = (1 / this.portfolios.length) * 100;
  },
};
</script>

<style src="./technologyService.css" scoped></style>
