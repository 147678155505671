<template>
  <div style="overflow: hidden">
    <div style="
        background: white;
        width: 100%;
        height: 100vh;
        z-index: 10;
        position: fixed;
        padding: 0px 16px;
      " v-if="isActiveHamburgerMenu">
      <div class="d-flex" style="height: 50px; align-items: center; margin-left: 16px">
        <img src="/img/logo.png" style="width: 150px; height: 70px" @click="goToHomePage" />
        <div style="
            justify-content: right;
            display: inline-flex;
            margin-right: 16px;
            width: 100%;
          ">
          <img src="/img/other/ic_menu.png" style="width: 24px; height: 24px; cursor: pointer" @click="closeMenu" />
        </div>
      </div>

      <div style="background: #f1f1f1; width: 100%; height: 1px"></div>
      <HamburgerMenu v-if="isActiveHamburgerMenu" />
    </div>
    <div class="header-menu container">
      <v-toolbar-title class="text-uppercase blue--text d-flex" style="align-items: center; width: 100%">
        <div style="cursor: pointer; margin-left: 16px; display: flex; align-items: center;" @click="goToHomePage">
          <LogoNav width="140" height="40" />
        </div>
        <div style="
            justify-content: right;
            display: inline-flex;
            margin-right: 16px;
            width: 100%;
          ">
          <img src="/img/other/ic_menu_white.png" style="width: 24px; height: 24px; cursor: pointer"
            @click="openMenu" />
        </div>
      </v-toolbar-title>
    </div>
    <div class="sub-banner font-poppins">
      <h1 class="title-banner font-poppins">
        Solusi Inovatif Karir & Bisnis Menuju
        Transformasi Digital Berkelanjutan
      </h1>
      <p style="color: white; text-align: center;">Kolaborasi Profesional melalui program Akselerasi Digital
        Upskilling, New Skilling, & Reskilling
        Bersama <b style="font-weight: bold; font-family: 'Poppins';">#CiptakanMasaDepan</b></p>
      <div class="menu-flex">
        <div class="ctn" @click="goToBootcamp">
          <BootcampIc :width="80" :height="80" />
          <p class="font-poppins" style="width: 85px; height: 30px">Bootcamp</p>
        </div>
        <div class="ctn" @click="goToClass">
          <PublicIc :width="80" :height="80" />
          <p class="font-poppins" style="width: 85px; height: 30px">Public Training</p>
        </div>
        <div class="ctn" @click="goToCorporateTraining">
          <CorpIc :width="80" :height="80" />
          <p class="font-poppins" style="width: 85px; height: 30px">Corporate Service</p>
        </div>
        <div class="ctn" @click="goToPrakerja">
          <PraIc :width="80" :height="80" />
          <p class="font-poppins" style="width: 85px; height: 30px">Kartu Prakerja</p>
        </div>
        <div class="ctn" @click="goToEvent">
          <EventIc :width="80" :height="80" />
          <p class="font-poppins" style="width: 85px; height: 30px">Event</p>
        </div>
      </div>
    </div>
    <div class="border-banner-bottom"></div>
    <div style="margin: 50px 0;">
      <div class="marquee-container">
        <div class="marquee-wrapper">
          <div class="our-client">
            <img v-for="(item, idx) in ourPartners" :key="idx" :src="item.img_url_mobile" class="image-client" />
          </div>
        </div>
        <div class="marquee-wrapper">
          <div class="our-client">
            <img v-for="(item, idx) in ourPartners" :key="idx" :src="item.img_url_mobile" class="image-client" />
          </div>
        </div>
      </div>
    </div>
    <div class="key-feature container">
      <div class="card-feature">
        <div class="content">
          <h2>Sejak 2020 HiColleagues Berkomitmen sebagai Mitra Akselerasi Digital Terpercaya
            & Kredibel</h2>
          <p>Inovasi & Layanan HiColleagues terbukti memberikan dampak signifikan terhadap
            skala pertumbuhan talenta digital dan perusahaan mitra </p>
          <div class="list">
            <div>
              <h2>1500+</h2>
              <p>Total Alumni</p>
            </div>
            <div>
              <h2>20+</h2>
              <p>Mitra Rekrutmen</p>
            </div>
            <div>
              <h2>16+</h2>
              <p>Mitra Corporate</p>
            </div>
            <div>
              <h2>4.8</h2>
              <p>Google
                Rating</p>
            </div>
          </div>
        </div>
      </div>
      <iframe class="card-image" src="https://www.youtube.com/embed/7mBp0DYzql4?si=O6Aw9SheRYdJm3a2"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
    <div class="unique-selling-proposition">
      <div class="unique-selling-proposition-container">
        <h2 class="text-center poppins-font usp-title">
          Mengapa HiColleagues menjadi Mitra Akselerasi Digital Terbaik
        </h2>
        <div class="content">
          <div class="card text-start" v-for="(item, idx) in usps" :key="idx">
            <img :src="item.img" alt="" width="60" height="60" />
            <div class="label">
              <p class="sub-title">{{ item.name }}</p>
              <p class="description">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container usp">
      <h2 class="text-center">Bangun Karakteristik Profesional untuk Jadi Talenta Kompeten</h2>
      <div class="card-img">
        <img src="/img/corporate/collaboration/training.png" alt="" />
      </div>
      <div class="card-usp" v-for="(usp, index) in usps2" :key="index">
        <div class="title-container mb-2">
          <img :src="usp.image" width="32" height="32" alt="" />
          <div>
            <h3>{{ usp.title }}</h3>
            <p>{{ usp.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: -70px;">
      <BannerMobile :banners="banners" />
    </div>
    <div class="list-of-acceleration" style="background-color: white;">
      <div class="list-of-acceleration-container">
        <h2 class="text-center my-1">Rekomendasi Program Akselerasi Digital Bootcamp</h2>
        <div class="card" style="padding-bottom: 60px">
          <div class="items" v-for="(item, idx) in bootcampClass" :key="idx" @click="
        $router.push(`/class/bootcamp/${item.slug_name}`)
        ">
            <div style="height: 160px">
              <img :src="item.small_image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
            </div>
            <div class="content">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.level">
                  <p>{{ item.level }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2>{{ item.name }}</h2>
              <p class="description-class">{{ item.description }}</p>
              <div style="height: 60px">
                <p style="color: #b80f0a; font-size: 16px; margin: 0" v-if="item.is_discount">
                  <del>{{ item.discount | toCurrency }}</del>
                </p>
                <h3 style="color: #162ca2; margin: 0" v-if="!item.is_discount">
                  {{ item.price | toCurrency }}
                </h3>
                <h3 style="color: #162ca2; margin: 0" v-else>
                  {{ toDiscount(item) }}
                </h3>
              </div>
            </div>
            <div class="due-date">
              <DateIc2 />
              <span class="font-poppins" style="font-size: 15px">{{
        item.registration_close_date | formatDateFull
      }} WIB</span>
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" @click="$router.push('/class/bootcamp')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: #F1F6FF; padding: 40px 0; border-radius: 32px;">
      <div class="list-of-acceleration-container">
        <h2 class="text-center my-1">Rekomendasi Program Akselerasi Digital</h2>
        <div class="card" style="padding-bottom: 60px">
          <div class="items" v-for="(item, idx) in accelerations" :key="idx" @click="
        $router.push(`/corporate/acceleration/detail/${item.slug_name}`)
        ">
            <div style="height: 160px">
              <img :src="item.small_image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
              <div class="label">
                <img :src="item.collaboration_feed" alt="" style="width: 80%" />
              </div>
            </div>
            <div class="content">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.type">
                  <p>{{ item.type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="item.class_type">
                  <p>{{ item.class_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2>{{ item.name }}</h2>
              <p class="description-class">{{ item.description }}</p>
              <div style="height: 60px">
                <p style="color: #b80f0a; font-size: 16px; margin: 0" v-if="item.is_discount">
                  <del>{{ item.discount | toCurrency }}</del>
                </p>
                <h3 style="color: #162ca2; margin: 0" v-if="!item.is_discount">
                  {{ item.price | toCurrency }}
                </h3>
                <h3 style="color: #162ca2; margin: 0" v-else>
                  {{ toDiscount(item) }}
                </h3>
              </div>
            </div>
            <div class="due-date">
              <DateIc2 />
              <span class="font-poppins" style="font-size: 15px">{{
        item.registration_close_date | formatDateFull
      }} WIB</span>
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" style="background-color: white;" @click="$router.push('/corporate/acceleration')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: white; padding: 40px 0;">
      <div class="list-of-acceleration-container">
        <h2 class="text-center my-1">Jelajahi Kelas Pelatihan Pilihan</h2>
        <div class="card" style="padding-bottom: 60px">
          <div class="items" v-for="(item, idx) in recommendationClass" :key="idx"
            @click="$router.push(`/class/public-training/${item.slug_name}`)">
            <div style="height: 160px">
              <img :src="item.small_image_url" alt=""
                style="width: 160px; height: 100%; display: block; margin: auto" />
            </div>
            <div class="content" style="border-radius: 80px;">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.level">
                  <p>{{ item.level }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2>{{ item.name }}</h2>
              <p class="description-class">{{ item.description }}</p>
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" @click="$router.push('/class/public-training')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: #F1F6FF; padding: 40px 0; border-radius: 32px;">
      <div class="list-of-acceleration-container">
        <p class="margin-0" style="font-weight: 500; color: #36373F; font-size: 16px;">Kartu Prakerja</p>
        <h2 style="text-align: left; margin-top: -12px;">Temukan Event Menarik & Buat Digital Skill-mu Makin Seru!</h2>
        <p class="margin-0" style="font-weight: 400; margin-top: -18px; color: #36373F; font-size: 15px;">Rekomendasi event untuk tingkatkan performa menjadi talenta digital kompeten!</p>
        <div class="portfolio-card">
          <div class="event-box" v-for="(item2, idx2) in prakerjaClass" :key="idx2">
            <div style="position: relative; display: inline-block;">
              <img :src="item2.thumbnail_url" alt="Content" class="event-box-img" @click="toDetailPrakerja(item2)" />
              <img src="/img/home/label-prakerja.png" alt="label-prakerja" class="label-prakerja" />
            </div>
            <div class="event-ctn">
              <div @click="toDetailPrakerja(item2)">
                <div class="event-ctn-label">
                  <span>Webinar</span>
                </div>
                <h3 class="judul-event" :title="item2.name">{{ item2.name }}</h3>
                <div class="event-ctn-info">
                  <DateIcon fill="#575966" :width="18" :height="18" />
                  <span class="name">{{ moment(item2.registration_close_date) }}</span>
                </div>
                <div class="event-ctn-info">
                  <clock fill="#575966" :width="18" :height="18" />
                  <span class="name">{{ moment(item2.registration_close_date, 'hh:mm') }} WIB</span>
                </div>
                <div class="event-ctn-info">
                  <people fill="#575966" :width="18" :height="18" />
                  <span class="name">50 Peserta</span>
                </div>
                <div class="event-ctn-info">
                  <methode fill="#575966" :width="18" :height="18" />
                  <span class="name">{{ item2.method | toMethode }}</span>
                </div>
                <div class="event-ctn-info" v-if="item2.instructor">
                  <speaker fill="#575966" :width="18" :height="18" />
                  <span class="name">{{ item2.instructor.name }}</span>
                </div>
              </div>
              <div style="display: flex; justify-content: center; margin: 16px 0">
                <Button name="TUKAR VOUCHER" width="100%" @click="() => toCheckoutPrakerja(item2)" />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" style="margin-top: 20px; background-color: white;" @click="$router.push('/corporate/acceleration')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong />
        </div>
      </div>
    </div>

    <div class="list-of-acceleration" style="background-color: white; padding: 40px 0;">
      <div class="list-of-acceleration-container">
        <p class="margin-0" style="font-weight: 500; color: #36373F; font-size: 16px;">Event</p>
        <h2 style="text-align: left; margin-top: -12px;">Temukan Event Menarik & Buat Digital Skill-mu Makin Seru!</h2>
        <p class="margin-0" style="font-weight: 400; margin-top: -18px; color: #36373F; font-size: 15px;">Rekomendasi event untuk tingkatkan performa menjadi talenta digital kompeten!</p>
        <div class="portfolio-card">
          <div class="event-box" v-for="(item2, idx2) in events" :key="idx2">
            <img :src="item2.img_urls.desktop.thumbnail" alt="Content" class="event-box-img" @click="toDetail(item2)" />
            <div class="event-ctn">
              <div @click="toDetail(item2)">
                <div class="event-ctn-label">
                  <span>{{ item2.category.name }}</span>
                </div>
                <h3 class="judul-event">{{ item2.name }}</h3>
                <div class="event-ctn-info">
                  <DateIcon fill="#575966" />
                  <span class="name">{{ moment(item2.session_schedules[0].date_start) }}</span>
                </div>
                <div class="event-ctn-info">
                  <clock fill="#575966" />
                  <span class="name">{{ item2.session_schedules[0].start_time }} - {{ item2.session_schedules[0].end_time
                    }} WIB</span>
                </div>
                <div class="event-ctn-info">
                  <people fill="#575966" />
                  <span class="name">{{ item2.participant_quota ? `${item2.participant_quota} Peserta` : "Tidak terbatas"
                    }}</span>
                </div>
                <div class="event-ctn-info">
                  <methode fill="#575966" />
                  <span class="name">{{ item2.method | toMethode }}</span>
                </div>
  
                <div class="event-ctn-info" v-if="item2.instructors.length">
                  <speaker fill="#575966" />
                  <span class="name">{{ item2.instructors[0].name
                    }}<span v-if="item2.instructors.length > 1">, {{ item2.instructors.length - 1 }}+
                      lainnya</span></span>
                </div>
                <div style="height: 35px" v-else></div>
                <div style="height: 50px;">
                  <p class="event-price" v-if="!checkIsEarlyBird(item2)">
                    {{ item2.price ? toCurrency(item2.price) : "GRATIS" }}
                  </p>
                  <div v-else>
                    <span class="event-price mr-2">{{ (item2.price - item2.discount_price) ? toCurrency(item2.price -
          item2.discount_price) : "GRATIS" }}</span><del class="discount-price">{{ toCurrency(item2.price)
                      }}</del><br>
                    <span style="font-size: 14px;"><b style="color: #162ca2; font-family: 'Poppins';">Early Bird
                        Promo</b>! Diskon {{ discountPercent(item2) }}%</span>
                  </div>
                </div>
                <div style="display: flex; justify-content: center; margin: 10px 0">
                  <Button :disabled="!item2.expired.length" name="DAFTAR" width="100%"
                    @click="() => toCheckout(item2)" />
                </div>
                <div style="height: 50px; margin-top: 20px;">
                  <div class="event-due-date" v-if="item2.expired.length">
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[0] }}</span>
                      <span>Hari</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[1] }}</span>
                      <span>Jam</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[2] }}</span>
                      <span>Menit</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item2.expired[3] }}</span>
                      <span>Detik</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" style="margin-top: 20px; background-color: white;" @click="$router.push('/event')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: #F1F6FF; padding: 40px 0; border-radius: 32px;">
      <div class="list-of-acceleration-container">
        <p class="margin-0" style="font-weight: 500; color: #36373F; font-size: 16px;">Corporate Service</p>
        <h2 style="text-align: left; margin-top: -12px;">Optimasi Bisnis dengan Transformasi Digital Berkelanjutan</h2>
        <p class="margin-0" style="font-weight: 400; margin-top: -18px; margin-bottom: 32px; color: #36373F; font-size: 15px;">Berbagai layanan optimalisasi bisnis Anda dengan solusi inovasi digital berkelanjutan, mempercepat efisiensi, dan memperkuat daya saing di era digital</p>
        <div class="card-1" v-for="(desc1, idx) in descs1" :key="idx">
          <img :src="desc1.img" class="img-2" />
          <div>
            <h3>{{ desc1.tittle }}</h3>
            <p>{{ desc1.desc }}</p>
          </div>
        </div>
        <div class="btn-stoke-blue" style="margin-top: 40px; background-color: #162CA2; color: white;" @click="$router.push('/corporate')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong fill="#FFFFFF" />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: white; padding: 40px 0;">
      <div class="list-of-acceleration-container">
        <h2 style="text-align: left; margin-top: -12px;">Testimoni Kepuasan Klien Kami</h2>
        <div class="card-2" v-for="(item, idx) in testimonies" :key="idx">
          <p class="text-center">"{{ item.feedback }}"</p>
          <div class="profile mt-5 d-flex" style="display: flex;">
            <div class="avatar">
              <v-img :src="$utils.loadImage(item.image_url)" alt="" width="56" height="56" class="avatar-image" />
            </div>
            <div class="testi-profile ml-5">
              <div class="name">{{ item.name }}</div>
              <div class="description">
                {{ item.job }}
              </div>
              <div class="description">
                {{ item.place }}
              </div>
              <v-img v-if="item.linkedin" src="/img/home/linkedin.png" alt="" width="24" height="24"
                @click="redirectReload(item.linkedin)" class="linkedin-image cursor-pointer" />
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" style="margin-top: 40px; background-color: white;" @click="$router.push('/alumni-stories')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong />
        </div>
      </div>
    </div>
    <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="container">
          <v-expansion-panels flat class="faq-card">
            <v-expansion-panel style="border-radius: 20px; margin-bottom: 10px;" v-for="(item, index) in questions"
              :key="index">
              <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)" style="border-radius: 20px;">
                <h4 style="line-height: 22px; font-size: 15px; color: #36373F;">{{ item.title }}</h4>
                <template v-slot:actions>
                  <div class="icon" v-if="item.active" style="margin-right: 20px;">
                    <Arrow />
                  </div>
                  <div v-else style="margin-right: 20px;">
                    <Arrow :down="!item.active" />
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-radius:0 0 20px 20px;">
                <div class="faq-answer" v-html="item.description"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        <!-- <AccordeonFaq
            v-for="(item, index) in questions"
            :key="index"
            :title="item.title"
            :description="item.description"
            :active="item.active"
            @toggle="toggleAccordion(index)"
          /> -->
          <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')" style="background-color: white; margin-top: 30px;">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import goTo from "vuetify/lib/services/goto";
// import ExchangeVoucher from "@/components/Prakerja/ExchangeVoucher"
import HamburgerMenu from "@/components/HamburgerMenu";
import LogoNav from "@/assets/icons/LogoNav.vue";
import BannerMobile from '../BannerMobile.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import DateIc2 from "../icons/Date.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Clock from "../icons/Clock.vue";
import DateIcon from "../icons/Date.vue";
import Speaker from "../icons/Speaker.vue";
import Methode from "../icons/Methode.vue";
import People from '../icons/People.vue';
import Button from "../../others/Button.vue";

// icon
import BootcampIc from '../icons/BootcampIc.vue';
import PublicIc from '../icons/Public.vue';
import CorpIc from '../icons/CorpIc.vue';
import PraIc from '../icons/PraIc.vue';
import EventIc from '../icons/Event.vue';
// import AccordeonFaq from "../AccordeonFaq.vue";

export default {
  components: {
    ArrowLong,
    LogoNav,
    Button,
    BootcampIc,
    PublicIc,
    CorpIc,
    PraIc,
    EventIc,
    // ExchangeVoucher,
    DateIc2,
    BannerMobile,
    HamburgerMenu,
    Arrow,
    DateIcon,
    Clock,
    Methode,
    Speaker,
    People,
    // AccordeonFaq
  },
  data() {
    return {
      mobileView: false,
      is_login_show: false,
      isActiveHamburgerMenu: false,
      addional_info: [
        {
          count: 1500,
          title: "Total alumni",
        },
        {
          count: 20,
          title: "Hiring Partner",
        },
        {
          count: 16,
          title: "Corporate Partner",
        },
      ],
      descs1: [
        {
          img: "/img/home/f4.png",
          tittle: "Corporate Collaboration",
          desc: "Siap berkolaborasi dengan multipihak untuk mendorong terciptanya talenta digital yang kompeten dan memiliki dampak positif memajukan perekonomian digital Indonesia.",
        },
        {
          img: "/img/home/f1.png",
          tittle: "Corporate Training",
          desc: "Menyediakan pelatihan dengan topik dan silabus yang dapat dikostumisasi serta jadwal fleksibel sesuai dengan kebutuhan perusahaan Anda.",
        },
        {
          img: "/img/home/f2.png",
          tittle: "Talent as a Service",
          desc: "Menyediakan talenta digital mulai dari junior hingga principle yang siap mendukung bisnis dan teknologi di perusahaan Anda dengan mekanisme headhunting dan outsourcing.",
        },
        {
          img: "/img/home/f3.png",
          tittle: "Technology Service",
          desc: "Menyediakan jasa pengembangan website, aplikasi mobile, management system, dan kebutuhan teknologi lainnya untuk membantu percepatan transformasi digital.",
        },
      ],
      questions: [],
      testimonies: [],
      accelerations: [],
      ourPartners: [],
      events: [],
      benefits: [
        {
          image: "/img/home/ic_kurikulum.png",
          title: "Kurikulum Terupdate",
          desc: "Kurikulum sesuai dengan industri digital saat ini",
          id: 1,
        },
        {
          image: "/img/home/ic_instruktur.png",
          title: "Instruktur Berpengalaman",
          desc: "Instruktur berkompetensi dan berpengalaman sesuai bidangnya",
          id: 2,
        },
        {
          image: "/img/home/ic_pelatihan_variatif.png",
          title: "Jenis Pelatihan Bervariatif",
          desc: "Kami memiliki lebih dari 50 judul pelatihan",
          id: 3,
        },
        {
          image: "/img/home/ic_praktical.png",
          title: "Pembelajaran secara Praktikal",
          desc: "80% materi yang diajarkan oleh instruktur bersifat praktikal",
          id: 4,
        },
        {
          image: "/img/home/ic_problem_solving.png",
          title: "Problem Solving & Critical Thinking",
          desc: "Memecahkan masalah secara sistematis dan komprehensif",
          id: 5,
        },
        {
          image: "/img/home/ic_guarantee.png",
          title: "100% Pelatihan Bergaransi",
          desc: "Dijamin belajar sampai bisa",
          id: 6,
        },
      ],
      usps: [
        {
          img: "/img/home/c1.png",
          name: "Kurikulum Up-to-Date",
          description: "Kami menyediakan kurikulum pelatihan dan sertifikasi yang up to date sesuai kebutuhan pasar kerja dan industri.",
        },
        {
          img: "/img/home/c2.png",
          name: "Instruktur Kompeten & Tersertifikasi",
          description: "Instruktur kami merupakan praktisi yang berpengalaman, kompeten, dan tersertifikasi. ",
        },
        {
          img: "/img/home/c3.png",
          name: "Jenis Pelatihan Variatif",
          description: "Memiliki variasi pelatihan di bidang teknologi, soft skill, dan bahasa untuk pemula hingga profesional.",
        },
        {
          img: "/img/home/c4.png",
          name: "Pembelajaran secara Praktikal",
          description: "80% materi diajarkan instruktur secara praktikal dan intensif untuk melatih memecahkan masalah secara sistematis dan komprehensif.",
        },
        {
          img: "/img/home/c5.png",
          name: "Sertifikat & Portfolio",
          description: "Peserta akan mendapatkan sertifikat penyelesaian dan sertifikat kompetensi, serta memiliki project portfolio sesuai dengan pelatihan yang diikuti.",
        },
        {
          img: "/img/home/c6.png",
          name: "Pelatihan Bergaransi",
          description: "Peserta dibimbing menjadi talenta digital profesional.",
        },
      ],
      usps2: [
        {
          image: "/img/home/ic_lamp.png",
          title: "Knowledge",
          desc: "Penerapan pengetahuan dalam pekerjaan memberikan dampak pada hasil kerja yang diinginkan dapat tercapai dengan lebih efisien dan efektif.",
        },
        {
          image: "/img/home/ic_tick.png",
          title: "Skill & Abilities",
          desc: "Menguasai keterampilan digital untuk mempercepat laju pengembangan SDM yang berkualitas.",
        },
        {
          image: "/img/home/ic_heart.png",
          title: "Attitudes",
          desc: "Karakteristik Individu dalam pekerja berupa perilaku, karakteristik, kecerdasan emosional & keyakinan yang memengaruhi gagasan, orang dan situasi.",
        },
      ],
      competitions: [
        {
          image: "/img/home/ic_skill.png",
          title: "Skill & Knowledge",
          desc: "Penerapan pengetahuan dalam pekerjaan memberikan dampak pada hasil kerja yang diinginkan dapat tercapai dengan lebih efisien dan efektif.",
          id: 1,
        },
        {
          image: "/img/home/ic_attitude.png",
          title: "Attitudes",
          desc: "Karakteristik Individu dalam pekerja berupa perilaku, karakteristik, kecerdasan emosional & keyakinan yang memengaruhi gagasan, orang dan situasi.",
          id: 2,
        },
        {
          image: "/img/home/ic_abilitites.png",
          title: "Abilities",
          desc: "Keahlian individu dalam pekerjaan seperti potensi menguasai keahlian dalam melakukan beragam tugas dalam pekerjaan / penilaian.",
          id: 3,
        },
      ],
      slides: [
        { image: "/img/other/banner_1.png", id: 1, route: "/public-bootcamp" },
        { image: "/img/other/banner_2.png", id: 2, route: "/prakerja" },
        { image: "/img/other/banner_3.png", id: 3 },
      ],
      publicClass: [
        { message: "Lebih dari satu peserta (max 10)" },
        { message: "Jadwal kelas telah ditentukan" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      privateClass: [
        { message: "Hanya satu peserta" },
        { message: "Dapat menentukan jadwal kelas" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      prakerjaClass: [],
      recommendationClass: [],
      competences: [
        {
          image: "/img/other/ic_skill_knowledge.png",
          title: "Skill & Knowledge",
          description:
            "Kemampuan untuk menerapkan pengetahuan dalam pekerjaan sehingga hasil kerja yang diinginkan dapat tercapai dengan lebih efisien dan efektif",
          id: 1,
        },
        {
          image: "/img/other/ic_attitudes.png",
          title: "Attitudes",
          description:
            "Perilaku yang dipelajari, karakteristik kecerdasan emosional, dan keyakinan yang ditunjukkan individu yang memengaruhi gagasan, orang, dan situasi",
          id: 2,
        },
        {
          image: "/img/other/ic_abilities.png",
          title: "Abilities",
          description:
            "Potensi seseorang individu untuk menguasai keahlian dalam melakukan beragam tugas dalam suatu pekerjaan atau suatu penilaian atas tindakan seseorang",
          id: 3,
        },
      ],
      banners: [],
      bootcampClass: [],
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
    };
  },
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },
  methods: {
    openMenu() {
      this.isActiveHamburgerMenu = true;
    },
    closeMenu() {
      this.isActiveHamburgerMenu = false;
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get('/v1/testimony?category=HOME&page=1&per_page=4');
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },
    async getAccelerationClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/6");
        this.accelerations = resp.data.data.classes;
        this.accelerations.splice(3, 1)
      } catch (err) {
        console.log(err);
      }
    },
    toggleAccordion(index) {
      this.questions[index].active = !this.questions[index].active;
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=HOME&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    async getDataEvents() {
      try {
        const resp = await this.$http.get("/v1/event?is_active=true");
        this.events = resp.data.data.events.map((item) => ({
          ...item,
          session_schedules: item.session_schedules ? item.session_schedules
            ? item.session_schedules
            : [
              {
                date_end: "",
                date_start: "",
                end_time: "",
                start_time: "",
              },
            ] : [{
              date_end: "",
              date_start: "",
              end_time: "",
              start_time: "",
            }],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
              desktop: {
                product_detail: "",
                purchase_detail: "",
                thumbnail: "",
              },
              mobile: {
                product_detail: "",
                purchase_detail: "",
                thumbnail: "",
              },
            },
          expired: [],
        }));
      } catch (err) {
        console.log(err);
      }
    },
    async getBanners() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/banner?category=GENERAL&is_active=true")
        .then((response) => {
          this.banners = response.data.data.banners;
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=CORPORATE");
        this.ourPartners = resp.data.data.logos;
      } catch (err) {
        console.log(err);
      }
    },
    async getBootcampClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/5?is_main=true");
        this.bootcampClass = resp.data.data.classes;
      } catch (err) {
        console.log(err);
      }
    },
    async getPublicClass() {
      try {
        const resp = await this.$http.get('/v1/class/category/1?is_main=true')
        this.recommendationClass = resp.data.data.classes
      } catch (err) {
        console.log(err);
      }
    },
    async getPrakerjaClass() {
      try {
        const resp = await this.$http.get('/v1/class/category/4?is_main=true')
        this.prakerjaClass = resp.data.data.classes
      } catch (err) {
        console.log(err);
      }
    },
    goToHomePage() {
      this.$router.push({ path: "/" }).then(() => {
        this.$router.go();
      });
    },
    goToClass() {
      this.$router.push({ path: "/class/public-training" }).then(() => {
        this.$router.go();
      });
    },
    goToKampusMerdeka() {
      this.$router.push({ path: "/kampusmerdeka" }).then(() => {
        this.$router.go();
      });
    },
    goToEvent() {
      this.$router
        .push({ path: '/event' })
        .then(() => { this.$router.go() })
    },
    goToPrakerja() {
      this.$router.push({ path: "/prakerja" }).then(() => {
        this.$router.go();
      });
    },
    goToCorporateTraining() {
      this.$router.push("/corporate")
    },
    goToBootcamp() {
      this.$router.push({ path: "/class/bootcamp" }).then(() => {
        this.$router.go();
      });
    },
    goToTalentAsAService() {
      this.$router.push({ path: "/talent-service" }).then(() => {
        this.$router.go();
      });
    },
    goToClassBootcamp(slug_name) {
      this.$router.push("/class/public-training/" + slug_name);
      sessionStorage.setItem("nav-item-active", 0);
      window.location.reload();
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    redirectReload(e) {
      if (e.slice(0, 5) == "https") {
        window.open(e, '_blank')
      } else {
        this.$router
          .push({ path: e })
          .then(() => { this.$router.go() })
      }
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    toDetailPrakerja(item) {
      this.$router.push("/class/prakerja/" + item.slug_name);
    },
    discountPercent(event) {
      return Math.round(((event.price - (event.price - event.discount_price)) / (event.price)) * 100)
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        this.events.forEach((item2) => {
          const end = new Date(item2.registration_close_date);
          const now = new Date();
          const distance = end - now;
          if (distance < 0) {
            item2.expired = [];
            return;
          }
          days = Math.floor(distance / _day);
          hours = Math.floor((distance % _day) / _hour);
          minutes = Math.floor((distance % _hour) / _minute);
          seconds = Math.floor((distance % _minute) / _second);
          item2.expired = [days, hours, minutes, seconds];
        });
      };
      setInterval(showRemaining, 1000);

    },
    checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date()) {
        return true
      } else {
        return false
      }
    },
    moment(date, val) {
      if (!val) {
        return moment(date).format("dddd, DD MMMM YYYY");
      }
      return moment(date).format(val);
    },
    async getTotalRegistran(id) {
      try {
        const resp = await this.$http.get(`/v1/event/${id}/registrant`);
        return resp.data.data.total_registrants
      } catch (error) {
        console.log(error);
        return 0
      }
    },
    async toCheckoutPrakerja(data) {
      await this.$http
        .get("/v1/class-detail/" + data.slug_name)
        .then((response) => {
          const classDetail = response.data.data.class_detail;
          const reqCheckout = JSON.stringify({
            class_id: classDetail.class_id,
            class_name: classDetail.name,
            price: classDetail.price,
            level: classDetail.level,
            session: classDetail.session_total,
            hour: classDetail.hours,
            image_url: classDetail.small_image_url,
            class_type: "PRAKERJA_CLASS",
            days: classDetail.days,
            methode: classDetail.method,
            category_id: classDetail.category_id,
          });

          localStorage.clear();
          localStorage.setItem("checkout", reqCheckout);
          window.location.href = "/checkout";
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    toDetail(item) {
      this.$router.push("/event/detail/" + item.id);
    },
    async toCheckout(item) {
      const total_registrants = await this.getTotalRegistran(item.id)
      if (total_registrants >= item.participant_quota && item.participant_quota !== 0) {
        this.notif.isAlertErr = true
        this.notif.alertErrMessage = "Kouta pendaftaran sudah penuh"
        return
      }
      this.$router.push("/checkout/event/" + item.id);
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    }
  },
  mounted() {
    this.getBanners()
    this.getBootcampClass()
    this.getDataEvents()
    this.getPrakerjaClass()
    this.getPublicClass()
    this.getClientLogo()
    this.getFaq()
    this.getAccelerationClass()
    this.countDownTimer();
    this.getTestimoni()
  },
};
</script>

<style src="./home.css" scoped></style>