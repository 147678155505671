<template>
    <div>
        <DekstopPortfolio v-if="!isMobile" />
        <MobilePortfolio v-if="isMobile" />
    </div>
  </template>
  
  <script>
  import DekstopPortfolio from "@/components/portfolio/desktop/Portfolio.vue"
  import MobilePortfolio from "@/components/portfolio/mobile/Portfolio.vue"
  
  export default {
    components: {
        DekstopPortfolio,
        MobilePortfolio,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        handleView() {
            this.isMobile = window.innerWidth <= 990;
        },
    },
    mounted() {
        this.$gtag.pageview(this.$route);
        this.$utils.scrollTop()
    },
    beforeMount() {
        this.handleView();
    },
  };
  </script>