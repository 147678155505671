<template>
  <div>
    <v-timeline style="margin-top: 50px" v-if="!isMobile">
      <v-timeline-item v-for="(item, i) in steps" :key="i" :color="item.color" fill-dot>
        <template v-slot:icon>
          <h2 style="color: #fff; font-weight: 600">{{ item.step }}</h2>
        </template>

        <div class="py-4">
          <h6 style="font-size: 22px">{{ item.title }}</h6>
          <div>
            {{ item.description }}
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>

    <div v-if="isMobile">
      <div class="line-vertical"></div>
      <div class="d-flex roadmap" v-for="n in steps" :key="n.id">
        <div style="width: 50px; z-index: 1">
          <div class="rounded-blue">
            <span>{{ n.step }}</span>
          </div>
        </div>
        <div style="margin-left: 12px; text-align: left; width: 100%">
          <h6 style="font-size: 15px; font-weight: 600; line-height: 23px">{{ n.title }}</h6>
          <span style="margin-top: 6px; font-size: 12px">{{ n.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      steps: [
        {
          color: '#70B8FF',
          step: 1,
          title: 'Pilih Program',
          description: 'Pilih program untuk tingkatkan skill sesuai minatmu.',
        },
        {
          color: '#70B8FF',
          step: 2,
          title: 'Isi Form Pendaftaran',
          description: 'Lengkapi data sebagai peserta melalui Form Pendaftaran.',
        },
        {
          color: '#70B8FF',
          step: 3,
          title: 'Pembayaran Biaya Kelas',
          description: 'Selesaikan pembayaran biaya kelas dan kelas akan aktif.',
        },
        {
          color: '#70B8FF',
          step: 4,
          title: 'Ikuti Kelas',
          description: 'Ikuti dan selesaikan kelas dengan Learning Management System (LMS)',
        },
        {
          color: '#70B8FF',
          step: 5,
          title: 'Sertifikat Pelatihan',
          description: 'Kelas selesai, dapatkan “Completion Certificate” dan siap berkarir di dunia kerja!',
        },
      ],
    };
  },
  methods: {
    handleView() {
      this.isMobile = window.innerWidth <= 990;
    },
  },
  mounted() {
    this.handleView();
  },
};
</script>

<style src="./css/stepOfPublicTraining.css" scoped></style>
