import { render, staticRenderFns } from "./StepOfPublicTraining.vue?vue&type=template&id=29c945d6&scoped=true&"
import script from "./StepOfPublicTraining.vue?vue&type=script&lang=js&"
export * from "./StepOfPublicTraining.vue?vue&type=script&lang=js&"
import style0 from "./css/stepOfPublicTraining.css?vue&type=style&index=0&id=29c945d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29c945d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VTimeline,VTimelineItem})
