<template>
    <div>
        <DekstopAlumniStoriesDetail v-if="!isMobile" />
        <MobileAlumniStoriesDetail v-if="isMobile" />
    </div>
  </template>
  
  <script>
  import DekstopAlumniStoriesDetail from "@/components/alumni-stories/detail/desktop/Detail.vue"
  import MobileAlumniStoriesDetail from "@/components/alumni-stories/detail/mobile/Detail.vue"
  
  export default {
    components: {
        DekstopAlumniStoriesDetail,
        MobileAlumniStoriesDetail,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        handleView() {
            this.isMobile = window.innerWidth <= 990;
        },
    },
    beforeMount() {
        this.handleView();
        this.$gtag.pageview(this.$route);
    },
  };
  </script>