<template>
  <div>
    <div class="text-center container" style="margin-top: 128px">
      <h2 class="sub-title-span">JELAJAHI KELAS</h2>
      <span style="margin-top: 20px">Temukan kelas terbaik yang cocok untuk meningkatkan kompetensi mu di
        era teknologi digital. Mulai dari sekarang!</span>
    </div>

    <div class="d-flex container justify-center" style="margin-top: 15px">
      <div v-for="(data, idx) in actionMethod" :key="idx" class="inactive" @click="getDataClass(idx + 1)"
        :class="{ active: layout === idx + 1 }">
        <span>{{ data }}</span>
      </div>
    </div>
    <div>
      <div v-for="i in subCategories" :key="i.name" :style="'background:' + i.color" class="bg">
        <div class="container">
          <h4 class="title-sub-category" v-if="i.name">{{ i.name }}</h4>
          <div>
            <v-row class="mt-5">
              <v-col v-for="n in i.recommendationClass" :key="n.id" sm="4" lg="3" :title="n.name">
                <v-card class="black--text category-list-class" router :to="'/class/public-training/' + n.slug_name">
                  <div :class="layout == 1 ? 'class-img-ctn' : ''">
                    <img :src="n.thumbnail_url" :class="layout == 1 ? 'class-list-img' : 'class-list-img-dd'" />
                  </div>
                  <div class="class-list-name">
                    <p class="name-class">
                      {{ n.name }}
                    </p>
                  </div>

                  <div class="info-dev">
                    <div class="level-div">
                      <img src="/img/home/ic_level.png" class="icon-class" />
                      <span>Dasar</span>
                    </div>
                    <div class="method-div">
                      <img src="/img/home/ic_method.png" class="icon-class" />
                      <span>Online / Offline</span>
                    </div>
                  </div>
                  <div class="desc-class">
                    <p>{{ n.description.substring(0, 130) + ".." }}</p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-if="!i.recommendationClass.length">
            <div class="text-center" style="margin:20px 0"><img src="/img/other/ic_coming_soon.png" height="300"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      layout: 1,
      actionMethod: [
        "TEKNOLOGI",
        "BAHASA",
        "SOFT SKILL"
      ],
      subCategories: [],
    };
  },
  methods: {
    async getDataClass(categoryId) {
      this.layout = categoryId;
      if (categoryId === 1) {
        this.subCategories = [
          {
            name: "KELAS PROGRAMMING",
            color: "#fff",
            recommendationClass: [],
          },
          {
            name: "KELAS DATA",
            color: "#F1F6FF",
            recommendationClass: [],
          },
          {
            name: "KELAS LAINNYA",
            color: "#fff",
            recommendationClass: [],
          },
        ]
      } else {
        this.subCategories = [
          {
            name: "",
            color: "#fff",
            recommendationClass: [],
          },
        ]
      }
      await this.$http
        .get(`/v1/class/category/${categoryId}`)
        .then((response) => {
          response.data.data.classes.forEach((element) => {
            if (categoryId === 1) {
              if (element.class_sub_category.id == 1) {
                this.subCategories[0].recommendationClass.push({
                  id: parseInt(element.id),
                  name: element.name,
                  thumbnail_url: element.thumbnail_url,
                  description: element.description,
                  level: element.level,
                  slug_name: element.slug_name,
                });
              } else if (element.class_sub_category.id == 2) {
                this.subCategories[1].recommendationClass.push({
                  id: parseInt(element.id),
                  name: element.name,
                  thumbnail_url: element.thumbnail_url,
                  description: element.description,
                  level: element.level,
                  slug_name: element.slug_name,
                });
              } else {
                this.subCategories[2].recommendationClass.push({
                  id: parseInt(element.id),
                  name: element.name,
                  thumbnail_url: element.thumbnail_url,
                  description: element.description,
                  level: element.level,
                  slug_name: element.slug_name,
                });
              }
            } else {
              this.subCategories[0].recommendationClass.push({
                id: parseInt(element.id),
                name: element.name,
                thumbnail_url: element.thumbnail_url,
                description: element.description,
                level: element.level,
                slug_name: element.slug_name,
              });
            }
          });
        })
        .catch((err) => console.error(err.response));
    },
    goToLinkedin() {
      window.open(
        'https://www.linkedin.com/company/hicolleagues/',
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    goToInstagram() {
      window.open(
        'https://www.instagram.com/hicolleagues/',
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    goToFacebook() {
      (
        'https://www.facebook.com/hicolleagues',
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    goToTikTok() {
      window.open(
        'https://www.tiktok.com/@hicolleagues',
        '_blank' // <- This is what makes it open in a new window.
      )
    },
  },
  mounted() {
    this.getDataClass(1)
  }
};
</script>

<style src="./css/publicTraining.css" scoped></style>
