<template>
  <div style="margin-top: 50px">
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      :isError="isError"
      @close="isAlertErr = false"
    />
    <div style="background-color: #162ca2">
      <v-img src="/img/corporate/tech-main.png" height="100%"></v-img>
    </div>
    <v-col style="background-color: #162ca2; padding: 25px 32px 20px 32px">
      <div class="lg-heading">
        Bangun Masa Depan Perusahaan melalui Peningkatan Skill Talenta
      </div>

      <v-spacer style="height: 25px" />
      <div class="lg-content">
        Perluas skala bisnis dalam dinamika transformasi digital dengan
        Upskilling &/ New Skilling talenta perusahaan melalui layanan Corporate
        Training HiColleagues sebagai mitra terpercaya & kredibel
      </div>
      <v-spacer style="height: 25px" />
      <v-btn
        block
        class="btn"
        style="color: #162ca2; background-color: #ffde59"
        elevation="0"
        @click="scrollToSection('collab_training')"
      >
        KOLABORASI SEKARANG
      </v-btn>
      <v-spacer style="height: 10px" />
      <v-btn block class="btn" elevation="0" @click="sendToWa">
        HUBUNGI KAMI
      </v-btn>
      <v-spacer style="height: 20px" />
    </v-col>
    <div style="background: linear-gradient(to bottom, #162ca2, #ffffff)">
      <v-col
        style="
          border-radius: 26px 26px 0px 0px;
          background-color: #ffffff;
          gap: 40px;
          padding: 37px 32px 40px 32px;
        "
      >
        <div class="md-heading">
          HiColleagues telah Dipercaya Perusahaan Ternama
        </div>
        <v-spacer style="height: 20px" />
        <div
          class="d-flex overflow-x-hidden overflow-y-hidden"
          style="height: 100%"
        >
          <v-col
            cols="6"
            v-for="(data, idx) in partners"
            :key="idx"
            class="
              d-flex
              justify-content-center
              align-content-center
              scroll-container
            "
          >
            <v-img :src="data.img_url_mobile" contain />
          </v-col>
          <v-col
            cols="6"
            v-for="(data, idx) in partners"
            :key="idx"
            class="
              d-flex
              justify-content-center
              align-content-center
              scroll-container-2
            "
          >
            <v-img :src="data.img_url_mobile" contain />
          </v-col>
        </div>
        <v-spacer style="height: 40px" />
        <div class="md-heading">Layanan Corporate Training HiColleagues</div>
        <v-spacer style="height: 10px" />
        <div class="md-content" style="text-align: center">
          Sesuaikan kebutuhan perusahaan melalui program unggulan HiColleagues
          dan berikan dampak ke peningkatan performa bisnis Anda
        </div>
        <v-spacer style="height: 20px" />
        <div class="layanan">
          <CorporateIcon :height="40" :width="40" style="border-radius: 100%" />
          <div class="sm-heading">General Corporate Training</div>
          <v-spacer style="height: 6px" />
          <div class="sm-content" style="text-align: justify">
            Berbasis pada pelatihan dan pengembangan skill talenta untuk
            meningkatkan performa perusahaan. Pelatihan mencakup pengetahuan,
            keterampilan, dan sikap, sementara pengembangan berfokus pada karir
            jangka panjang. Training Needs Analysis diupayakan dalam mewujudkan
            program berjalan secara maksimal
          </div>
        </div>
        <v-spacer style="height: 20px" />
        <div class="layanan">
          <CorporateIcon :height="40" :width="40" style="border-radius: 100%" />
          <div class="sm-heading">Certified Corporate Training</div>
          <v-spacer style="height: 6px" />
          <div class="sm-content" style="text-align: justify">
            Berbasis pada pelatihan talenta melalui Upskilling dan New Skilling
            untuk meningkatkan performa perusahaan yang mencakup pengetahuan,
            keterampilan, dan sikap. Menerapkan Training Needs Analysis dan
            sertifikasi dari lisensi global sebagai bukti tercapainya program
            dan validasi peningkatan performa talenta.
          </div>
        </div>
      </v-col>

      <v-col
        style="
          background: linear-gradient(to bottom, #ffffff, #f1f6ff);
          padding: 40px 32px;
          border-radius: 0px 0px 26px 26px;
        "
      >
        <div>
          <div class="md-heading">
            Program Unggulan Akselerasi Skill Digital
          </div>
          <v-spacer style="height: 10px" />
          <div class="md-content" style="text-align: center; font-size: 15px">
            Rekomendasi program pelatihan untuk akselerasi skill talenta digital
            dan peningkatan performa talenta perusahaan
          </div>
          <v-spacer style="height: 20px" />
          <v-tabs
            grow
            style="border-bottom: 1px solid #d3d6e1"
            v-model="program"
          >
            <v-tab
              v-for="item in programTabs"
              :key="item.value"
              :value="item.value"
              @click="chooseTab(item)"
              style="
                width: 125px;
                letter-spacing: normal;
                text-transform: capitalize;
                font-size: 14px;
                line-height: 18px;
              "
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>
          <v-window
            v-model="program"
            :touch="{ left: swipeLeft, right: swipeRight }"
            style="padding: 16.5px 0"
          >
            <v-window-item v-for="index in programTabs.length" :key="index">
              <v-col v-for="(data, idx) in courseMaterial" :key="idx">
                <div class="program-card-header">
                  <MessageIcon :width="22" :height="22" />
                  {{ data.title }}
                </div>
                <div class="program-card-content">
                  {{ data.description }}
                  <v-spacer style="height: 10px"></v-spacer>
                  <b>Cakupan Pembelajaran:</b>
                  <ul>
                    <li
                      v-for="(item2, idx2) in data.scope_learning"
                      :key="idx2"
                    >
                      {{ item2.name }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-window-item>
          </v-window>
        </div>

        <div class="consultation-card">
          <div
            class="md-heading"
            style="font-size: 15.5px; font-weight: 700; line-height: 22px"
          >
            Belum Menemukan Program Sesuai Kebutuhan Perusahaan Anda?
          </div>
          <div
            class="lg-content"
            style="color: #36373f; font-weight: 400; font-size: 14px"
          >
            Konsultasikan kebutuhan perusahaan Anda dan kami siap mangakomodir
            melalui layanan <b>Training Need Analysis</b> untuk memaksimalkan
            proyeksi bisnis Anda.
          </div>
          <v-btn
            class="btn"
            style="background-color: #162ca2; color: #ffffff"
            block
            @click="sendToWa"
          >
            KONSULTASI SEKARANG
          </v-btn>
        </div>
      </v-col>
    </div>
    <div style="padding: 40px 32px">
      <div class="md-heading text-center" style="font-size: 20px">
        Mengapa Memilih HiColleagues sebagai Mitra Corporate Training
      </div>
      <v-spacer style="height: 6px"></v-spacer>
      <div
        class="md-content text-center"
        style="font-size: 16px; line-height: 25px"
      >
        HiColleagues telah membuktikan melalui
        <b> 100+ pelatihan yang telah dilaksanakan </b> dan memberi dampak
        positif terhadap akselerasi skill talenta mitra
      </div>
      <v-spacer style="height: 20px" />
      <div style="gap: 20px">
        <v-col
          style="padding: 12px 0"
          v-for="(usp, index) in uspData"
          :key="index"
          :style="{
            gap: '10px',
            borderBottom:
              index === uspData.length - 1 ? 'none' : '1px solid #acafbf',
          }"
        >
          <v-spacer style="height: 10px"></v-spacer>
          <img
            :src="`/img/corporate/w-${index + 1}.png`"
            alt="aaa"
            style="width: 42px; height: 42px; border-radius: 100%"
          />
          <v-spacer style="height: 5px"></v-spacer>
          <div class="sm-heading" style="font-size: 15px">
            {{ usp.title }}
          </div>
          <v-spacer style="height: 10px"></v-spacer>
          <div class="sm-content" style="text-align: justify; font-size: 13px">
            {{ usp.content }}
          </div>
          <v-spacer style="height: 10px"></v-spacer>
        </v-col>
        <v-img
          src="/img/corporate/tech/main-2.png"
          aspect-ratio="1"
          style="border-radius: 16px"
        />
      </div>
      <v-spacer style="height: 40px" />
    </div>
    <StepCollaborationMobile
      title="Langkah Kolaboratif Kemitraan"
      :steps="steps"
    />

    <div style="margin-top: 30px" class="partner">
      <div class="md-heading mb-4" style="font-size: 20px">
        HiColleagues Partner
      </div>
      <div style="overflow-x: scroll; display: flex; gap: 40px; width: 100%">
        <div class="Partner">
          <div class="logo">
            <v-img
              :src="dataMitra[partnerModel].image_url"
              height="150"
              width="150"
            />
          </div>
          <v-spacer style="height: 20px"></v-spacer>
          <div class="alibaba-title">{{ dataMitra[partnerModel].name }}</div>
          <v-spacer style="height: 10px"></v-spacer>
          <div class="alibaba-content text-justify">
            {{ dataMitra[partnerModel].description }}
          </div>
        </div>
      </div>
    </div>
    <!-- Pagination -->
    <v-row
      style="margin: 20px 0px 20px 0px; padding: 0px 20px; width: 100%"
      class="d-flex align-center"
    >
      <v-col cols="8">
        <div class="slider">
          <div
            class="indicator"
            :style="{
              width: `${sliderIndicatorPartner}%`,
              transform: `translateX(${indicatorValuePartner}%)`,
              transition: `transform 0.5s`,
            }"
          ></div>
        </div>
      </v-col>
      <v-col
        cols="4"
        class="d-flex"
        style="justify-content: flex-end; gap: 11px"
      >
        <v-btn
          v-for="index in 2"
          fab
          :key="index"
          @click="index === 1 ? PartnersPrev() : PartnersNext()"
          class="btn-mobile-slider-corp"
          elevation="0"
        >
          <ArrowIcon
            style="color: white"
            :direct="index === 1 ? 'right' : 'left'"
          />
        </v-btn>
      </v-col>
    </v-row>
    <div class="project">
      <h2>Proyek yang Telah Berjalan</h2>
      <div style="">
        <div :key="index">
          <div class="img-card">
            <img
              :src="portfolios[portfoliosModel].image_url"
              alt=""
              height="400"
            />
          </div>
          <div class="card-port">
            <div class="content-port">
              <h2>{{ portfolios[portfoliosModel].title }}</h2>
              <p class="type" v-if="portfolios[portfoliosModel].label">
                {{ portfolios[portfoliosModel].label }}
              </p>
              <p class="short">{{ portfolios[portfoliosModel].program }}</p>
              <p class="desc">{{ portfolios[portfoliosModel].description }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Pagination -->
      <v-row
        style="margin: 0px 10px; padding: 0px 10px"
        class="d-flex align-center"
      >
        <v-col cols="8">
          <div class="slider">
            <div
              class="indicator"
              :style="{
                width: `${sliderIndicatorPortfolios}%`,
                transform: `translateX(${indicatorValuePortfolios}%)`,
                transition: `transform 0.5s`,
              }"
            ></div>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="d-flex"
          style="justify-content: flex-end; gap: 11px"
        >
          <v-btn
            v-for="index in 2"
            fab
            :key="index"
            @click.prevent="index === 1 ? PortfoliosPrev() : PortfoliosNext()"
            class="btn-mobile-slider-corp"
            elevation="0"
          >
            <ArrowIcon
              style="color: white"
              :direct="index === 1 ? 'right' : 'left'"
            />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="testimony">
      <h1>Testimoni Kepuasan Klien Kami</h1>
      <v-window v-model="testimonyModel">
        <v-window-item
          v-for="index in testimony.length"
          :key="index"
          style="padding: 10px; height: 220px"
        >
          <v-card
            v-for="(data, index) in slicedTestimony(testimonyModel)"
            :key="index"
            style="
              background-color: #ffffff;
              box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1);
              border-radius: 16px;
              padding: 17px 20px;
              height: 190px;
            "
          >
            <v-row>
              <!-- Message -->
              <v-col cols="12">
                <div class="feedback">“{{ data.feedback }}”</div>
              </v-col>
              <!-- User -->
              <v-col cols="12">
                <v-row>
                  <!-- Image -->
                  <v-col cols="3">
                    <v-img
                      :src="$utils.loadImage(data.image_url)"
                      width="60"
                      height="60"
                      style="border-radius: 50%"
                    />
                  </v-col>
                  <!-- Name and Position -->
                  <v-col cols="9">
                    <div style="font-size: 12px; font-weight: 700">
                      {{ data.name }}
                    </div>
                    <div style="font-size: 10px">
                      {{ data.job }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
      <!-- Pagination -->
      <v-row
        style="margin: 0px 10px; padding: 0px 10px"
        class="d-flex align-center"
      >
        <v-col cols="8">
          <div class="slider">
            <div
              class="indicator"
              :style="{
                width: `${sliderIndicatorTestimony}%`,
                transform: `translateX(${indicatorValueTestimony}%)`,
                transition: `transform 0.5s`,
              }"
            ></div>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="d-flex"
          style="justify-content: flex-end; gap: 11px"
        >
          <v-btn
            v-for="index in 2"
            fab
            :key="index"
            @click="index === 1 ? testimonyPrev() : testimonyNext()"
            class="btn-mobile-slider-corp"
            elevation="0"
          >
            <ArrowIcon
              style="color: white"
              :direct="index === 1 ? 'right' : 'left'"
            />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="form-konsultasi" style="padding: 0px 30px">
      <div class="header text-center">
        <h1 style="font-size: 16px; font-weight: 700; line-height: 22px">
          Formulir Pendaftaran Konsultasi
        </h1>
      </div>
      <div class="sub-heading mt-1 text-center">
        <p
          style="
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
          "
        >
          Langkah tepat optimasi bisnis melalui layanan Corporate Service
          bersama HiColleagues
        </p>
        <v-form
          class="text-start"
          style="margin-top: 60px"
          id="collab_training"
        >
          <v-col cols="100">
            <label class="form-label" for="company"
              >Nama Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                >*</span
              ></label
            >
            <v-text-field
              class="form-input text-form"
              name="company"
              v-model="form.corporate_name"
              outlined
              placeholder="Masukkan Nama Perusahaan"
              :full-width="true"
            ></v-text-field>
          </v-col>
          <v-col cols="100">
            <label class="form-label" for="website"
              >Website Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                >*</span
              ></label
            >
            <v-text-field
              class="form-input text-form"
              name="website"
              v-model="form.web_url"
              outlined
              placeholder="Masukkan Alamat Website Perusahaan"
            ></v-text-field>
          </v-col>
          <v-col cols="100">
            <label class="form-label" for="pic-name"
              >Nama Lengkap PIC<span style="color: rgba(184, 15, 10, 1)"
                >*</span
              ></label
            >
            <v-text-field
              class="form-input text-form"
              name="pic-name"
              outlined
              v-model="form.full_name"
              placeholder="Masukkan Nama Lengkap PIC"
            ></v-text-field>
          </v-col>
          <v-col cols="100">
            <label class="form-label" for="email"
              >Email PIC<span style="color: rgba(184, 15, 10, 1)"
                >*</span
              ></label
            >
            <v-text-field
              class="form-input text-form"
              name="email"
              outlined
              v-model="form.email"
              placeholder="Masukkan Email PIC"
              max-width
            ></v-text-field>
          </v-col>
          <v-col cols="100">
            <label class="form-label" for="occupation">Okupasi PIC</label>
            <v-text-field
              class="form-input text-form"
              name="occupation"
              outlined
              v-model="form.job_pic"
              placeholder="Masukkan Okupasi PIC"
            >
            </v-text-field>
          </v-col>
          <v-col cols="100">
            <label class="form-label" for="phone-number"
              >No. Whatsapp PIC<span style="color: rgba(184, 15, 10, 1)"
                >*</span
              ></label
            >
            <v-text-field
              class="form-input text-form"
              name="phone-number"
              outlined
              placeholder="Masukkan No. Whatsapp PIC"
              type="number"
              v-model="form.phone_number"
              hide-spin-buttons
            >
              <template v-slot:prepend-inner>
                <img
                  src="/img/other/flag-indonesia.png"
                  alt=""
                  width="30"
                  height="20"
                  style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)"
                />
                <span style="color: #575966" class="mx-3">+62</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="form-collab">
            <label class="form-label" for="collaboration"
              >Proyeksi Kolaborasi/Kemitraan</label
            >
            <v-text-field
              class="form-input text-form"
              solo
              flat
              background-color="rgba(247, 249, 253, 1)"
              placeholder="Corporate Training"
              disabled
            />
          </v-col>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="program-category"
                >Kategori Kolaborasi<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-select
                class="form-input text-form"
                outlined
                v-model="program"
                :items="programTabs"
                :item-text="'title'"
                :item-value="'value'"
                @change="getCourseMaterial"
                placeholder="Pilih Program Pelatihan"
              />
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="program-category">Program Pelatihan<span
                  style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-select class="form-input text-form" v-model="form.program" outlined :items="courseMaterial" :item-text="'title'"
                :item-value="'title'" placeholder="Pilih Program Pelatihan" />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-3">
            <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
              <template v-slot:label>
                <h5 style="font-size: 12px" class="label-checkbox">
                  Dengan mengirim formulir ini saya menyetujui<a href="#">
                    Syarat & Ketentuan </a
                  >untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                </h5>
              </template>
            </v-checkbox>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-6">
            <v-btn
              elevation="0"
              :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`"
              @click="onSubmit"
              >KIRIM
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </div>
    <div class="faq" style="margin-top: 60px">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="container">
            <v-expansion-panels flat class="faq-card">
              <v-expansion-panel
                style="border-radius: 20px; margin-bottom: 10px"
                v-for="(item, index) in questions"
                :key="index"
              >
                <v-expansion-panel-header
                  disable-icon-rotate
                  @click="openFaq(index)"
                  style="border-radius: 20px"
                >
                  <h3 style="font-size: 14px; line-height: 20px">
                    {{ item.title }}
                  </h3>
                  <template v-slot:actions>
                    <div class="icon" style="margin-left: 5px">
                      <Arrow :down="!item.active" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="border-radius: 0 0 20px 20px">
                  <div style="font-size: 12px" v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div
            class="btn-stoke-blue cursor-pointer mt-5"
            @click="$router.push('/faq')"
          >
            <span class="font-poppins" style="color: #162ca2"
              >LIHAT SELENGKAPNYA</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CorporateIcon from "@/components/ilustration/CorporateIcon.vue";
import Popup from "@/components/others/Popup.vue";
import ArrowIcon from "@/assets/icons/Arrow.vue";
import Arrow from "@/assets/icons/Arrow.vue";
import StepCollaborationMobile from "../../StepCollaborationMobile.vue";
export default {
  name: "MobileCorporateTraining",
  components: {
    CorporateIcon,
    Popup,
    Arrow,
    ArrowIcon,
    StepCollaborationMobile,
  },
  data() {
    return {
      program: 0,
      programLength: 2,
      programTabs: [
        {
          title: "General Corp. Training Program",
          value: 0,
        },
        {
          title: "Certified Corp. Training Program",
          value: 1,
        },
      ],
      partners: [],
      portfolios: [],
      courseMaterial: [],
      steps: [
        {
          image_url: "/img/corporate/proyek.png",
          title: "Observasi & Identifikasi Kebutuhan",
          description:
            "Pencarian informasi yang aktual melalui proses interview secara mendalam, dengan ini kebutuhan dan ekspektasi mitra dapat diidentifikasi dengan jelas untuk memudahkan kami dalam memberikan solusi yang tepat sesuai kebutuhan.",
        },
        {
          image_url: "/img/corporate/proyek.png",
          title: "Perancangan Konsep Pelatihan",
          description:
            "Setelah mengidentifikasi & memetakan kebutuhan, kami akan mulai merancang konsep pelatihan sebagai strategi untuk mewujudkan hasil yang maksimal bagi peserta pelatihan. Silabus, mekanisme, & segala hal pendukung pelaksanaan pelatihan.",
        },
        {
          image_url: "/img/corporate/proyek.png",
          title: "Validasi Konsep Pelatihan",
          description:
            "Perancangan konsep pelatihan yang HiColleagues tawarkan akan divalidasi atau disetujui oleh mitra baik dari sisi silabus, mekanisme, & biaya pelatihan sebagai bentuk dimulainya kerjasama antar kedua belah pihak.",
        },
        {
          image_url: "/img/corporate/proyek.png",
          title: "Pelaksanaan Pelatihan",
          description:
            "Program pelatihan akan diselenggarakan oleh profesional tim HiColleagues. Memastikan agar program kerjasama ini berjalan dengan maksimal, efektif, dan efisien sesuai kebutuhan atau ekspektasi dari mitra perusahaan.",
        },
        {
          image_url: "/img/corporate/proyek.png",
          title: "Laporan, Penilaian, & Sertifikat Pelatihan",
          description:
            "Secara berkala tim HiColleagues akan memberikan laporan mengenai penyelenggaraan program sebagai bentuk komitmen yang akuntabel. Hal ini didukung juga dengan adanya penilaian & penerbitan sertifikat bagi masing-masing peserta.",
        },
        {
          image_url: "/img/corporate/proyek.png",
          title: "Analisis Pasca Pelatihan",
          description:
            "Pasca pelaksanaan program pelatihan tim HiColleagues melaksanaan analisis mengenai penyelenggaraan, capaian hasil, hingga ulasan sebagai bentuk validasi penyempurnaan strategi ke depan bagi mitra.",
        },
      ],
      uspData: [
        {
          title: "Telah dipercaya oleh 30+ Perusahaan Besar Di Indonesia",
          content:
            "HiColleagues telah dipercaya oleh 30+ perusahaan besar di Indonesia dikarenakan berhasil mengembangkan SDM pada perusahaan sehingga siap dalam menghadapi transformasi digital.",
        },
        {
          title: "Pelatihan Sertifikasi dari Instansi Global",
          content:
            "Selain upskilling dan new skilling, perusahaan memiliki kesempatan untuk memilih pelatihan sekaligus mendapat sertifikasi dari Instansi global.",
        },
        {
          title: "Waktu Pelatihan Fleksibel",
          content:
            "Pelatihan fleksibel dapat secara online maupun offline dan dapat disesuaikan tanpa mengganggu waktu kerja",
        },
        {
          title: "Instruktur Profesional",
          content: "Training dengan instruktur yang berpengalaman dibidangnya",
        },
        {
          title: "Kurikulum up-to-date",
          content:
            "HiColleagues telah dipercaya oleh 30+ perusahaan besar di Indonesia dikarenakan berhasil mengembangkan SDM pada perusahaan sehingga siap dalam menghadapi transformasi digital.",
        },
        {
          title: "Metode Pembelajaran yang Interaktif",
          content:
            "Metode pembelajaran secara praktikal (80% praktik dan 20% teori)",
        },
      ],
      kemitraan: 0,
      collaborativePartnership: [
        {
          id: 1,
          title: "Observasi & Identifikasi Kebutuhan",
          desc: "Pencarian informasi yang aktual melalui proses interview secara mendalam, dengan ini kebutuhan dan ekspektasi mitra dapat diidentifikasi dengan jelas untuk memudahkan kami dalam memberikan solusi yang tepat sesuai kebutuhan.",
        },
        {
          id: 2,
          title: "Perancangan Konsep Pelatihan",
          desc: "Setelah mengidentifikasi & memetakan kebutuhan, kami akan mulai merancang konsep pelatihan sebagai strategi untuk mewujudkan hasil yang maksimal bagi peserta pelatihan. Silabus, mekanisme, & segala hal pendukung pelaksanaan pelatihan.",
        },
      ],
      alertErrMessage: "",
      isAlertErr: false,
      isError: false,
      testimonyModel: 0,
      portfoliosModel: 0,
      partnerModel: 0,
      indicatorValueTestimony: 0,
      sliderIndicatorTestimony: 0,
      indicatorValuePortfolios: 0,
      sliderIndicatorPortfolios: 0,
      indicatorValuePartner: 0,
      sliderIndicatorPartner: 0,
      dataMitra: [],
      testimony: [],
      questions: [],
      showDesc: [],
      is_confirm: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "TRAINING",
        web_url: "",
        job_pic: "",
      },
    };
  },
  watch: {
    program() {
      this.getCourseMaterial();
    },
  },
  methods: {
    chooseTab(item) {
      this.program = item.value;
    },
    swipeLeft() {
      if (this.program < this.programLength - 1) {
        this.program++;
      }
    },
    swipeRight() {
      if (this.program > 0) {
        this.program--;
      }
    },
    kemitraanNext() {
      this.kemitraan =
        this.kemitraan + 1 === this.steps.length ? 0 : this.kemitraan + 1;
    },
    kemitraanPrev() {
      this.kemitraan =
        this.kemitraan - 1 < 0 ? this.steps.length - 1 : this.kemitraan - 1;
    },

    testimonyNext() {
      if (this.testimonyModel < this.testimony.length - 1) {
        this.testimonyModel += 1;
        this.indicatorValueTestimony += this.sliderIndicatorTestimony + 50;
      }

      if (this.indicatorValueTestimony === 100) {
        return;
      }
    },
    testimonyPrev() {
      if (this.testimonyModel > 0) {
        this.testimonyModel -= 1;
      }

      if (this.indicatorValueTestimony === 0) {
        return;
      }

      this.indicatorValueTestimony -= this.sliderIndicatorTestimony + 50;
    },
    PortfoliosNext() {
      if (this.portfoliosModel < this.portfolios.length - 1) {
        this.portfoliosModel += 1;
        this.indicatorValuePortfolios += this.sliderIndicatorPortfolios + 50;
      }

      if (this.indicatorValuePortfolios === 100) {
        return;
      }
    },
    PortfoliosPrev() {
      if (this.portfoliosModel > 0) {
        this.portfoliosModel -= 1;
      }

      if (this.indicatorValuePortfolios === 0) {
        return;
      }

      this.indicatorValuePortfolios -= this.sliderIndicatorPortfolios + 50;
    },
    PartnersNext() {
      if (this.partnerModel < this.dataMitra.length - 1) {
        this.partnerModel += 1;
        this.indicatorValuePartner += this.sliderIndicatorPartner + 50;
      }

      if (this.indicatorValuePartner === 100) {
        return;
      }
    },
    PartnersPrev() {
      if (this.partnerModel > 0) {
        this.partnerModel -= 1;
      }

      if (this.indicatorValuePartner === 0) {
        return;
      }

      this.indicatorValuePartner -= this.sliderIndicatorPartner + 50;
    },
    slicedTestimony(index) {
      return [this.testimony[index]];
    },
   
    async getPortofolio() {
      try {
        const resp = await this.$http.get(
          "/v1/project-portfolio?category=TRAINING"
        );
        this.portfolios = resp.data.data.project_portfolios;
        this.sliderIndicatorPortfolios =
          100 / resp.data.data.project_portfolios.length + 4;
      } catch (err) {
        console.log(err);
      }
    },
    async getMitra() {
      try {
        const resp = await this.$http.get(
          "/v1/partner?category=TRAINING&page=1&per_page=9"
        );
        this.dataMitra = resp.data.data.partners;
        this.sliderIndicatorPartner = 100 / resp.data.data.partners.length + 8;
      } catch (err) {
        console.log(err);
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=TRAINING&page=1&per_page=3"
        );
        this.testimony = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=TRAINING");
        this.partners = resp.data.data.logos;
      } catch (err) {
        console.log(err);
      }
    },
    async getCourseMaterial() {
      try {
        const resp = await this.$http.get(
          `/v1/corporate-training?category=${
            this.program ? "CERTIFIED" : "GENERAL"
          }`
        );
        this.courseMaterial = resp.data.data.corporate_trainings;
        this.form.program = "";
      } catch (error) {
        console.log(error);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active;
      this.questions.forEach((e) => {
        e.active = false;
      });
      this.questions[idx].active = !valDef;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=ACCELERATION&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return;
      }
      if (
        !this.form.corporate_name ||
        !this.form.full_name ||
        !this.form.job_pic ||
        !this.form.email ||
        !this.form.phone_number ||
        !this.form.category ||
        !this.form.web_url
      ) {
        this.alertErrMessage = "Harap isi semua form yang tersedia";
        this.isAlertErr = true;
        this.isError = true;
        return;
      }
      try {
        await this.$http.post("/v1/corporate-registration", this.form);
        this.alertErrMessage = "Success";
        this.isAlertErr = true;
        this.isError = false;
        this.clearForm();
      } catch {
        this.alertErrMessage = "Error saat submit data";
        this.isAlertErr = true;
        this.isError = true;
      }
    },
    clearForm() {
      this.is_confirm = false
      this.form.full_name = ""
      this.form.email = ""
      this.form.phone_number = ""
      this.form.corporate_name = ""
      this.form.category = ""
      this.form.web_url = ""
      this.form.job_pic = ""
      this.form.program = ""
      this.notes = ""
      this.program = ""
    },
  },
  mounted() {
    this.getFaq();
    this.getTestimoni();
    this.getClientLogo();
    this.getCourseMaterial();
    this.getPortofolio();
    this.getMitra();
  },
  beforeMount() {
    this.sliderIndicatorTestimony = 37;
  },
};
</script>

<style src="./corporateTraining.css" scoped></style>
