<template>
  <div>
    <DesktopCorporateCollaboration v-if="!isMobile"/>
    <MobileCorporateCollaboration v-if="isMobile"/>
  </div>
</template>
<script>
import DesktopCorporateCollaboration from "@/components/corporate/collaboration/desktop/CorporateCollaboration.vue";
import MobileCorporateCollaboration from  "@/components/corporate/collaboration/mobile/CorporateCollaborationMobile.vue"

export default {
  components: {
    DesktopCorporateCollaboration,
    MobileCorporateCollaboration,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = window.innerWidth <= 990;
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
};
</script>
