<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.5498 9.18335V13.325C3.5498 14.8417 3.5498 14.8417 4.98314 15.8083L8.9248 18.0833C9.51647 18.425 10.4831 18.425 11.0748 18.0833L15.0165 15.8083C16.4498 14.8417 16.4498 14.8417 16.4498 13.325V9.18335C16.4498 7.66668 16.4498 7.66668 15.0165 6.70001L11.0748 4.42501C10.4831 4.08335 9.51647 4.08335 8.9248 4.42501L4.98314 6.70001C3.5498 7.66668 3.5498 7.66668 3.5498 9.18335Z"
			stroke="#575966"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.5837 6.35835V4.16669C14.5837 2.50002 13.7503 1.66669 12.0837 1.66669H7.91699C6.25033 1.66669 5.41699 2.50002 5.41699 4.16669V6.30002"
			stroke="#575966"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M10.5255 9.15836L11.0005 9.90003C11.0755 10.0167 11.2421 10.1334 11.3671 10.1667L12.2171 10.3834C12.7421 10.5167 12.8838 10.9667 12.5421 11.3834L11.9838 12.0584C11.9005 12.1667 11.8338 12.3584 11.8421 12.4917L11.8921 13.3667C11.9255 13.9084 11.5421 14.1834 11.0421 13.9834L10.2255 13.6584C10.1005 13.6084 9.89213 13.6084 9.76713 13.6584L8.95047 13.9834C8.45047 14.1834 8.06713 13.9 8.10047 13.3667L8.15047 12.4917C8.1588 12.3584 8.09213 12.1584 8.0088 12.0584L7.45047 11.3834C7.1088 10.9667 7.25047 10.5167 7.77547 10.3834L8.62547 10.1667C8.7588 10.1334 8.92547 10.0084 8.99213 9.90003L9.46713 9.15836C9.76713 8.70836 10.2338 8.70836 10.5255 9.15836Z"
			stroke="#575966"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: "22",
		},
		height: {
			type: String,
			default: "22",
		},
	},
};
</script>
