<template>
    <div style="position: relative;">
        <div class="back-left" @click="isClickScrool">
            <Arrow  direct="left" />
        </div>
        <div class="back-right" @click="isClickScrool(true)">
            <Arrow direct="right" />
        </div>
    <div class="testimoni" >
        <div class="content" v-for="(item, idx) in testimonies" :key="idx">
            <div style="display: flex; justify-content: center;">
                <Qoute />
            </div>
            <div style="height: 105px; margin-bottom: 10px;">
                <p class="mt-6 text-center line-clamp">{{ item.feedback }}</p>
            </div>
            <div class="mentee">
                <div class="mentee-img">
                    <img :src="$utils.loadImage(item.image_url)" alt="profile">
                </div>
                <div class="mentee-info">
                    <h3 class="line-clamp line-name" style="-webkit-line-clamp: 4;" :title="item.name">{{ item.name }}</h3>
                    <p class="major">{{ item.place }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination" v-if="total_page > 1">
        <div :class="`${current_page === idx ? 'color-blue' : 'color-gray'} circle`" v-for="idx in total_page" :key="idx" @click="next(idx)"></div>
    </div>
</div>
</template>

<script>
import Qoute from '@/assets/icons/Qoute.vue';
import Arrow from '../../assets/icons/Arrow.vue';

export default {
    name: "Testimoni",
    components: {
        Qoute,
        Arrow
    },
    data() {
        return {
            main_testimonies: [],
            testimonies: [],
            current_page: 1,
            total_page: 1,
            perPage: 3,
        }
    },
    methods: {
        async getTestimoni() {
            try {
                const resp = await this.$http.get("/v1/testimony?category=PRAKERJA&page=1&per_page=9")
                this.main_testimonies = resp.data.data.testimonies
                this.isTypeDevice()
            } catch (error) {
                console.log(error);
            }
        },
        next(idx) {
            this.current_page = idx
            if (window.innerWidth <= 990) {
                const total = idx * 1
                this.testimonies = this.main_testimonies.slice(total - 1,total);
            }else {
                const total = idx * 3
                this.testimonies = this.main_testimonies.slice(total - 3,total);
            }
            
        },
        isTypeDevice() {
            if (window.innerWidth <= 990) {
                this.testimonies = this.main_testimonies.slice(0,1);
                const total = this.main_testimonies.length / 1
                this.total_page = total < 1 ? 1 : Math.floor(total);
            } else {
                this.testimonies = this.main_testimonies.slice(0,3);
                const page = this.$utils.paginate(this.main_testimonies.length, 3)
                this.total_page = page;
            }
            if (this.total_page > 3) {
                this.total_page = 3
            }
        },
        isClickScrool(toRight) {
            if (toRight) {
                let total = this.current_page + 1
                if (total > this.total_page) {
                    total = 1
                }
                this.next(total)
            } else {
                let total = this.current_page - 1
                if (total <= 0) {
                    total = this.total_page
                }
                this.next(total)
            }
        },
        autoScroll() {
            setInterval(() => {
                let total = this.current_page + 1
                if (total > this.total_page) {
                    total = 1
                }
                this.next(total)
            }, 15000);
        },
    },
    mounted() {
        this.autoScroll()
        this.getTestimoni()
    }
}
</script>

<style src="./css/alumni.css" scoped></style>