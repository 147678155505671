<template>
  <div>
     <DesktopDetailClass v-if="!isMobile" />
     <MobileDetailClass v-if="isMobile"/>
  </div>
</template>

<script>
import DesktopDetailClass from '@/components/public-training/desktop/DetailClass'
import MobileDetailClass from '@/components/public-training/mobile/DetailClass'
export default {
  components: {
    DesktopDetailClass,
    MobileDetailClass
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = window.innerWidth <= 990
      }
  },
  mounted() {
    this.$gtag.pageview(this.$route)
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView()
  },
}

</script>