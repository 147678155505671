<template>
    <div>
       <DesktopLogin v-if="!isMobile" />
       <MobileLogin v-if="isMobile" />
  
    </div>
  </template>
  
  <script>
  import DesktopLogin from '@/components/login/desktop/Login'
  import MobileLogin from '@/components/login/mobile/Login'
  export default {
    components: {
        DesktopLogin,
        MobileLogin
    },
    data() {
      return{
        isMobile: false
      }
    },
    methods: {
        handleView() {
            this.isMobile = window.innerWidth <= 990
        }
    },
    mounted() {
      this.handleView()
      this.$gtag.pageview(this.$route)
    }
  }
  </script>